import React, { Component } from "react";
import { connect } from "react-redux";

import {
  loadTipos,
  tipoSelected
} from "modules/evaluacion/actions/tipo-actions";
import ListTipo from "./components/list-tipo";
import Content from "../_hoc/Content";

class ListTipoPage extends Component {
  componentDidMount() {
    if (!this.props.tipos.length) {
      if(localStorage.getItem('token') && localStorage.getItem('role') === 'marketing' ){
        this.props.loadTipos();
      }
    }
  }

  onClickEvaluar = (e, k) => {
    e.preventDefault();
    this.props.tipoSelected(k.name, k.id);
    
    localStorage.setItem('selected_pais', '');
    localStorage.setItem('selected_agencia', '');
    localStorage.setItem('selected_marca', '');
    localStorage.setItem('selected_periodo', '');

    this.props.history.push({
      pathname: "/evaluacion"
    });
  }; 

  render() {
    const { loading_tipos, tipos } = this.props;
    return (
      <ListTipo
        loading={loading_tipos}
        tipos={tipos}
        onClickEvaluar={this.onClickEvaluar}
        
      />
    );
  }
}

const mapStateToProps = state => ({
  tipos: state.tipoStore.tipos,
  loading_tipos: state.tipoStore.loading_tipos
});

const tunedComponent = Content(ListTipoPage, {
  title: "Evaluar Agencias",
  links: [{ title: "EVALUAR AGENCIAS" }]
});

export default connect(mapStateToProps, { loadTipos, tipoSelected })(
  tunedComponent
);
