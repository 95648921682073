import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Content from "../_hoc/Content";
import { fetchPeriodos, deletePeriodo } from "modules/periodo/actions/periodo-actions";

import ListPeriodo from "./components/list-periodo";
import swal from "sweetalert";

class ListPeriodoPage extends Component {
  componentDidMount() {
    this.props.fetchPeriodos();
  }

  onConfirmDelete = (e, data) => {
    let ambito = this;
    e.preventDefault();
    swal({
      title: "Estás Seguro?",
      text:
        "una vez eliminado, no podras recuperar la periodo " +
        data.fecha +
        "!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        ambito.props.deletePeriodo(data.id).then(e => {
          if (e.type === "DELETE_PERIODO_SUCCESS") {
            swal("La periodo ha sido eliminada con éxito", {
              icon: "success"
            });
          }

          if (e.type === "DELETE_PERIODO_FAIL") {
            if (e.error.response.status === 500) {
              swal("Ocurrió un problema interno en el servidor", {
                icon: "error"
              });
            }
          }
        });
      }
    });
  };

  render() {
    return (
      <section>
        <div className="acciones_top">
          <Link to="/periodos/create" className="btn btn-primary">
            Crear Nuevo Periodo
          </Link>
        </div>
        <ListPeriodo
          loading={this.props.loading_periodos}
          data={this.props.periodos}
          onConfirmDelete={this.onConfirmDelete}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  periodos: state.periodoStore.periodos,
  loading: state.periodoStore.loading_periodos
});

export default connect(mapStateToProps, { fetchPeriodos, deletePeriodo })(
  Content(ListPeriodoPage, {
    title: "Listado de Periodos",
    links: [{ title: "Periodos" }]
  })
);
