export function fetchLogs(page = 1, tipo = "") {
    return {
        types: ["LOADING_LOGS", "LOGS_SUCCESS", "FAIL"],
        payload: {
            request: {
                url:
                    "/logs?page=" +
                    page +
                    "&tipo=" +
                    tipo
            }
        }
    };
}
