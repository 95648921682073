import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { renderField,  SelectSimpleField, renderCheckbox } from "routes/_helpers/campos";
import validate from "../helpers/validate-agencia";

import CrudContent from "../../_hoc/CrudContent";




class FormAgencia extends Component {


  
  componentWillReceiveProps = nextProps => {
    const { data } = nextProps;

    if (data.name !== this.props.data.name) {
      this.props.initialize(data);
    }
  };



  render() {
    const { handleSubmit, submitting, button_message ,  tipos } = this.props;

 
    return (
      <form onSubmit={handleSubmit} className="formulario-crud element-box">
        <p>Ingrese los datos de la Agencia</p>
        <br />

        <Field
          type="text"
          component={renderField}
          name="description"
          id="description"
          label="Nombre"
          placeholder="Ingresar nombre de la Marca"
        />


        <Field 
          component={SelectSimpleField}
          name="tipo_id"
          label="Tipos"
          options={tipos}
          id="state-tipos-select"
        />


        
        <Field
          type="checkbox"
          component={renderCheckbox}
          name="state"
          id="state"
          label="Estado"
        />



        <div className="top-line">
          <Link to="/agencias" className="btn btn-black">
            Regresar
          </Link>
          <button
            type="submit"
            disabled={submitting}
            className="btn btn-primary left-15"
          >
            {button_message}
          </button>
        </div>
      </form>
    );
  }
}

export default CrudContent(
  reduxForm({ form: "agencia", validate })(FormAgencia)
);
