export function consolidado(_id) {
  return {
    types: ["LOADING_CONSOLIDADO", "CONSOLIDADO_SUCCESS", "FAIL"],
    payload: {
      request: {
        url: "/consolidado/" + _id + "/edit"
      }
    }
  };
}

export function consolidadoAnual(_id){
  return {
    types: ["LOADING_CONSOLIDADO", "CONSOLIDADO_SUCCESS", "FAIL"],
    payload: {
      request: {
        url: "/consolidado-anual/" + _id 
      }
    }
  };
}

export function fetchConsolidados( agencia = "", tipo = "",  periodo = '', page = 1) {
  return {
    types: ["LOADING_CONSOLIDADOS", "CONSOLIDADOS_SUCCESS", "FAIL"],
    payload: {
      request: {
        url: "/consolidado?page=" +
        page +
        "&tipo=" +
        tipo +
        "&agencia=" +
        agencia +
        "&periodo=" +
        periodo
      }
    }
  };
}

export function saveConsolidado($consolidado_id, $datos){
  return {
    types: ["LOADING_SAVE_CONSOLIDADO", "SAVE_CONSOLIDADO_SUCCESS", "FAIL"],
    payload: {
      request: {
        method: 'PUT',
        url: '/consolidado/' + $consolidado_id,
        data: {
          data: JSON.stringify($datos)
        }
      }
    }
  }
}
