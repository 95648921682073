import {
    LOADING_AGENCIAS,
    AGENCIAS_LIST_SUCCESS,
    FETCH_AGENCIA,
    FETCH_AGENCIA_SUCCESS,
    SAVE_AGENCIA_SUCCESS,
    UPDATE_AGENCIA_SUCCESS,
} from '../constants';

const initialState = {
    loading_agencias : true,
    agencias : [],
    agencia : {},
    tipos_select: []
}


export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_AGENCIAS:
            return Object.assign({}, state,{
                loading_agencias: true
            });

        case AGENCIAS_LIST_SUCCESS:
            return Object.assign({}, state,{
                agencias : action.payload.data,
                loading_agencias: false
            })
        case 'CREATE_AGENCIA_SUCCESS':
            return Object.assign({}, state, {
                tipos_select : action.payload.data.tipos,
            })
        case FETCH_AGENCIA:
            return Object.assign({}, state, {
                agencia: {}
            })
        case FETCH_AGENCIA_SUCCESS:
            return Object.assign({}, state, {
                agencia: action.payload.data.agencia,
                tipos_select : action.payload.data.tipos,
            })
        case SAVE_AGENCIA_SUCCESS: 
            return Object.assign({}, state, {
                agencia: action.payload.data
            })
        case UPDATE_AGENCIA_SUCCESS:
            return Object.assign({}, state, {
                agencia: action.payload.data
            }) 
        case 'DELETE_AGENCIA_SUCCESS':
            return Object.assign({}, state, {
                agencias : state.agencias.filter( e => e.name !== action.payload.data.name)
            })
        default:
            return state;
    }
}