export  default values => {
    const errors = {};
    if (!values.email) {
        errors.email = "Este campo es obligatorio";
    }

    if (!values.password) {
        errors.password = "Este campo es obligatorio";
    }

    if(!validateEmail(values.email)){
        errors.email = "Email invalido";
    }

    return errors;
};


function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}