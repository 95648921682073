import React, { Component } from "react";
import FormAgencia from "./components/form-agencia";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  saveAgencia,
  fetchAgencia,
  updateAgencia,
  createAgencia
} from "modules/agencia/actions/agencia-actions";

class FormAgenciaPage extends Component {
  state = {
    agencia: {},
    redirect: false
  };

  componentDidMount() {
    let { _name } = this.props.match.params;
    if (_name) {
      this.props.fetchAgencia(_name);
    } else {
      this.props.createAgencia();
    }
  }

  handleSubmit = agencia => {
    console.log(agencia);
    let ambito = this;
    if (!agencia.id) {
      return this.props.saveAgencia(agencia).then(function(e) {
        ambito.setState({
          redirect: true
        });
      });
    } else {
      return this.props.updateAgencia(agencia).then(function(e) {
        ambito.setState({
          redirect: true
        });
      });
    }
  };

  render() {
    let { _name } = this.props.match.params;
    let { agencia,  tipos_select } = this.props;

    let breadcrumb = [
      { title: "Agencias", to: "/agencias" },
      { title: _name ? _name : "Nueva Agencia" }
    ];

    let title = _name ? "Editar Agencia" : "Nueva Agencia";
    let button_message = _name ? "Actualizar" : "Registrar";

    return this.state.redirect ? (
      <Redirect to="/agencias" />
    ) : (
      <FormAgencia
        onSubmit={this.handleSubmit}
        title={title}
        data={agencia}
        links={breadcrumb}
        tipos={tipos_select}
        button_message={button_message}
      />
    );
  }
}

/* export default FormAgenciaPage; */

const mapStateToProps = state => ({
  agencia: state.agenciaStore.agencia,
  tipos_select: state.agenciaStore.tipos_select
});

export default connect(mapStateToProps, {
  saveAgencia,
  fetchAgencia,
  updateAgencia,
  createAgencia
})(FormAgenciaPage);
