export function fetchFichas(estado = "", agencia = "", tipo = "",  periodo = '', page = 1) {
  return {
    types: ["LOADING_FICHAS", "FICHAS_SUCCESS", "FAIL"],
    payload: {
      request: {
        url:
          "/ficha?page=" +
          page +
          "&estado=" +
          estado +
          "&tipo=" +
          tipo +
          "&agencia=" +
          agencia +
          "&periodo=" +
          periodo
      }
    }
  };
}
