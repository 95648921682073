import React, { Component } from "react";
import InputPaso from "./input-paso";
import { ratingByObjective, checkFloat } from "./helper";

export default class InputPasoContainer extends Component {
  state = {
    rating: "N.A",
    objectivo: '',
    actual: ''
  };

  componentDidMount() {
    let { respuestas } = this.props.data;

    if (respuestas) {
      if (respuestas.length) {
        let inputs = JSON.parse(respuestas[0].answers);
        this.setState({
          rating: respuestas[0].rating,
          objectivo: inputs.objectivo,
          actual: inputs.actual
        });
      }
    }
  }

  onChangeObjectivo = e => {
    this.setState(
      checkFloat(e.currentTarget.value)
        ? {
            objectivo: e.currentTarget.value,
            rating: ratingByObjective(
              this.state.actual,
              parseFloat(e.currentTarget.value)
            )
          }
        : {}
    );
  };

  onChangeActual = e => {
    this.setState(
      checkFloat(e.currentTarget.value)
        ? {
            actual: e.currentTarget.value,
            rating: ratingByObjective(
              parseFloat(e.currentTarget.value),
              this.state.objectivo
            )
          }
        : {}
    );
  };

  handleAdminSelect = e => {
    this.setState({
      rating: e.currentTarget.value,
    });
  }


  render() {
    return (
      <InputPaso
        data={this.props.data}
        rating={this.state.rating}
        objectivo={this.state.objectivo}
        actual={this.state.actual}
        onChangeObjectivo={this.onChangeObjectivo}
        onChangeActual={this.onChangeActual}
        handleAdminSelect={this.handleAdminSelect}
      />
    );
  }
}
