import React from "react";

export default function Multistep(props) { 
  if(props.pasos[props.pasos.length - 1].name !== "comentario")
    props.pasos.push({ name: "comentario" });
  const pasos = props.pasos.map((e, i) => {
    return (
      <li
        key={e.name + "-" + i}
        className={props.currentStep > i ? "progtrckr-doing" : "progtrckr-todo"}
        onClick={() => props.handleClick(i + 1)}
        value="0"
      >
        <span>Paso {i + 1}</span>
      </li>
    );
  });

  return (
    <section className="steps-content box-style">
      <div className="step-progress">
        <div className="multi-step">
          <ol className="progtrckr">{pasos}</ol>
          <div>{props.children}</div>
          <div className="footer-buttons">
            <div>
              {props.currentStep !== 1 ? (
                <span
                  className="btn btn-primary"
                  id="next-button"
                  onClick={props.handleBack}
                >
                  volver
                </span>
              ) : null}
            </div>
            <div>
              {props.currentStep === props.pasos.length ? (
                <button
                  className="btn btn-primary"
                  onClick={props.onHandleResume}
                  id="next-button"
                >
                Generar Resumen
                </button>
              ) : (
                <span
                  className="btn btn-primary"
                  id="next-button"
                  onClick={props.handleNext}
                >
                  Continuar
                </span>
              )}
              <button
                className="btn btn-black left-15"
                onClick={e => props.onHandleSaveOut(e, 0)}
                id="save-ketp"
              >
                Guardar
              </button>
              <button
                className="btn btn-black left-15"
                onClick={e => props.onHandleSaveOut(e, 1)}
                id="save-out"
              >
                Salir
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
