import React from 'react'
import logo from "../_assets/logo.png";
import { Link } from "react-router-dom";

export default function Logo(props) {
  const role = localStorage.getItem('role');
    return (
    <div className="logo-w">
        <Link className="logo" to={ role === 'admin' ? '/consolidados': '/' }>
          <div className="logo-element">

            <img alt="logo" src={logo} style={{ 'width' : '50px' }} />
          </div>
          <div className="logo-label">
            <h4>
            Evaluación de Agencias  <br/> de Publicidad Alicorp
            </h4>
          </div>
        </Link>
      </div>
    )
}
