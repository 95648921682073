import React, { Component } from "react";
import NormaPaso from "./norma-paso";
import { ratingByEbes, checkFloat, ratingByKpisInputs } from "./helper";

export default class NormaPasoContainer extends Component {
  state = {
    rating: "N.A",
    questions: this.props.data.questions,
    inputs: {}
  };

  componentDidMount() {
    let { respuestas } = this.props.data;
    if (respuestas) {
      if (respuestas.length) {
        let inputs = JSON.parse(respuestas[0].answers);
        console.log(inputs);
        this.setState({
          rating: respuestas[0].rating,
          inputs
        });
      }
    }
  }

  handleResultadoInput = (e, data) => {
    if (checkFloat(e.currentTarget.value)) {
      let objecto = {};
      objecto["input_result_" + data.id] = e.currentTarget.value;
      objecto["rating_" + data.id] = ratingByEbes(
        parseFloat(e.currentTarget.value),
        this.state.inputs["input_norma_" + data.id]
      );
      let inputs = Object.assign({}, this.state.inputs, objecto);
      let rating = ratingByKpisInputs(inputs);

      this.setState({
        inputs,
        rating
      });
    }
    /* */
  };

  handleNormaInput = (e, data) => {
    if (checkFloat(e.currentTarget.value)) {
      let objecto = {};
      objecto["input_norma_" + data.id] = e.currentTarget.value;
      objecto["rating_" + data.id] = ratingByEbes(
        this.state.inputs["input_result_" + data.id],
        parseFloat(e.currentTarget.value)
      );
      let inputs = Object.assign({}, this.state.inputs, objecto);
      let rating = ratingByKpisInputs(inputs);

      this.setState({
        inputs,
        rating
      });
    }
  };

  handleChechbox = (e, data) => {
    let objecto = {};
    if (e.currentTarget.checked) {
      objecto["rating_" + data.id] = "N.A";
      objecto["input_norma_" + data.id] = "";
      objecto["input_result_" + data.id] = "";
      objecto["disabled_" + data.id] = "checked";
    } else {
      objecto["rating_" + data.id] = "";
      objecto["disabled_" + data.id] = "";
    }

    let inputs = Object.assign({}, this.state.inputs, objecto);
    let rating = ratingByKpisInputs(inputs);
    this.setState({
      inputs,
      rating
    });
  };

  handleAdminSelect = e => {
    this.setState({
      rating: e.currentTarget.value,
    });
  }

  render() {
    return (
      <NormaPaso
        data={this.props.data}
        rating={this.state.rating}
        handleResultadoInput={this.handleResultadoInput}
        handleNormaInput={this.handleNormaInput}
        handleChechbox={this.handleChechbox}
        inputs={this.state.inputs}
        handleAdminSelect={this.handleAdminSelect}
      />
    );
  }
}
