import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Content from "../_hoc/Content";
import { fetchUsers, deleteUser } from "modules/users/user-actions";

import ListUser from "./components/list-user";
import swal from "sweetalert";
import Search from "components/Search";

class ListUserPage extends Component {
  state = {
    page: 1,
    estado: "",
    tipo: "",
    q: ""
  };

  componentDidMount() {
    this.props.fetchUsers();
  }

  onConfirmDelete = (e, data) => {
    let ambito = this;
    e.preventDefault();
    swal({
      title: "Estás Seguro?",
      text:
        "una vez eliminado, no podras recuperar el usuario " + data.name + "!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        ambito.props.deleteUser(data.id).then(e => {
          if (e.type === "DELETE_USER_SUCCESS") {
            swal("el usuario ha sido eliminada con éxito", {
              icon: "success"
            });
          }

          if (e.type === "DELETE_USER_FAIL") {
            if (e.error.response.status === 500) {
              swal("Ocurrió un problema interno en el servidor", {
                icon: "error"
              });
            }
          }
        });
      }
    });
  };

  handleSearch = value => {
    this.props.fetchUsers(value, this.state.estado, 1, this.state.tipo).then(e => {
      this.setState({
        q: value,
        page: 1
      });
    });
  };

  onClickState = state => {
    this.props.fetchUsers(this.state.q, state, 1, this.state.tipo).then(e => {
      this.setState({
        estado: state,
        page: 1
      });
    });
  };

  handlePageClick = page => {
    this.props
      .fetchUsers(this.state.q, this.state.estado, page.selected + 1, this.state.tipo)
      .then(e => {
        this.setState({
          page: page.selected + 1
        });
      });
  };

  onClickTipe = tipo => {
    this.props
      .fetchUsers(this.state.q, this.state.estado, 1, tipo)
      .then(e => {
        this.setState({
          tipo: tipo
        });
      });
  }
  onClean = e => {
    this.setState({
      page: 1,
      estado: "",
      tipo: "",
      q: ""
    });
    this.props.fetchUsers();
  };

  render() {
    return (
      <section>
        <div className="acciones_top espaciados">
          <div>
            <Link to="/users/create" className="btn btn-primary">
              Crear Nuevo Usuario
            </Link>

            <a href="/users-excels" className="btn btn-green ml-1">Exportar usuarios</a>


          </div>
          <Search handleSearch={this.handleSearch} />
        </div>
        {this.state.estado || this.state.q || this.state.tipo ? (
          <span className="message-span">
            <i>
              {this.state.q ? (
                <span>resultados encontrados con '{this.state.q}' /</span>
              ) : (
                ""
              )}
              {this.state.tipo ? (
                <span>{' ' + (this.state.tipo === 'admin' ? 'Administrador' : 'Marketing')} /</span>
              ) : (
                ""
              )}
              {this.state.estado ? (
                <span>
                  {" "}
                  {this.state.estado === "active"
                    ? "Activos"
                    : "Inactivos"}{" "}
                </span>
              ) : (
                ""
              )}
              <span className="search-clean" onClick={this.onClean}>
                {" "}
                limpiar búsqueda{" "}
              </span>
            </i>
          </span>
        ) : (
          ""
        )}
        <ListUser
          loading={this.props.loading}
          users={this.props.users}
          onClickState={this.onClickState}
          onClickTipe={this.onClickTipe}
          onConfirmDelete={this.onConfirmDelete}
          handlePageClick={this.handlePageClick}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  users: state.userStore.users,
  loading: state.userStore.loading_users
});

export default connect(mapStateToProps, { fetchUsers, deleteUser })(
  Content(ListUserPage, {
    title: "Listado de Usuarios",
    links: [{ title: "Users" }]
  })
);
