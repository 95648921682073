import React, { Component } from "react";
import MetaPaso from "./meta-paso";
import { ratingByAll, checkFloat } from "./helper";

export default class MetaPasoContainer extends Component {
  state = {
    rating: "N.A",
    objectivo: '',
    actual: '',
    maxima: '',
    objectivo: '',
    minima: ''
  };

  componentDidMount() {
    let { respuestas } = this.props.data;

    if (respuestas) {
      if (respuestas.length) {
        let inputs = JSON.parse(respuestas[0].answers);
        this.setState({
          rating: respuestas[0].rating,
          objectivo: inputs.objectivo === null ? "" : inputs.objectivo,
          actual: inputs.actual === null ? "" : inputs.actual,
          maxima: inputs.maxima === null ? "" : inputs.maxima,
          minima: inputs.minima === null ? "" : inputs.minima,
        });
      }
    }
  }

  onChangeMaxima = e => {
    if (this.validateMetaPaso(e.currentTarget.value, this.state.objectivo, this.state.minima)) {
      this.setState(
        checkFloat(e.currentTarget.value)
          ? {
            maxima: e.currentTarget.value,
            rating: ratingByAll(
              parseFloat(e.currentTarget.value),
              this.state.objectivo,
              this.state.minima,
              this.state.actual
            )
          }
          : {}
      );
    } else {
      this.setState(
        checkFloat(e.currentTarget.value)
          ? {
            maxima: e.currentTarget.value,
            rating: "N.A",
            actual: "",
          }
          : {}
      );
    }
  };


  onChangeObjectivo = e => {
    if (this.validateMetaPaso(this.state.maxima, e.currentTarget.value, this.state.minima)) {
      this.setState(
        checkFloat(e.currentTarget.value)
          ? {
            objectivo: e.currentTarget.value,
            rating: ratingByAll(
              this.state.maxima,
              parseFloat(e.currentTarget.value),
              this.state.minima,
              this.state.actual
            )
          }
          : {}
      );
    } else {
      this.setState(
        checkFloat(e.currentTarget.value)
          ? {
            objectivo: e.currentTarget.value,
            rating: "N.A",
            actual: "",
          }
          : {}
      );
    }
  };


  onChangeMinima = e => {
    if (this.validateMetaPaso(this.state.maxima, this.state.objectivo, e.currentTarget.value)) {
      this.setState(
        checkFloat(e.currentTarget.value)
          ? {
            minima: e.currentTarget.value,
            rating: ratingByAll(
              this.state.maxima,
              this.state.objectivo,
              parseFloat(e.currentTarget.value),
              this.state.actual
            )
          }
          : {}
      );
    } else {
      this.setState(
        checkFloat(e.currentTarget.value)
          ? {
            minima: e.currentTarget.value,
            actual: "",
            rating: "N.A"
          }
          : {}
      );
    }
  };


  onChangeActual = e => {


    this.setState(
      checkFloat(e.currentTarget.value)
        ? {
          actual: e.currentTarget.value,
          rating: ratingByAll(
            this.state.maxima,
            this.state.objectivo,
            this.state.minima,
            parseFloat(e.currentTarget.value),
          )
        }
        : {}
    );
  };


  validateMetaPaso = (maxima, objectivo, minima) => {
    if (isNaN(maxima) || isNaN(objectivo) || isNaN(minima)) {

      return false
    }
    if (parseFloat(maxima) > parseFloat(objectivo) && parseFloat(objectivo) > parseFloat(minima)) {
      return true;
    }
    return false;
  }

  handleAdminSelect = e => {
    this.setState({
      rating: e.currentTarget.value,
    });
  }


  render() {
    return (
      <MetaPaso
        data={this.props.data}
        rating={this.state.rating}
        objectivo={this.state.objectivo}
        maxima={this.state.maxima}
        minima={this.state.minima}
        actual={this.state.actual}
        onChangeActual={this.onChangeActual}
        onChangeMaxima={this.onChangeMaxima}
        onChangeObjectivo={this.onChangeObjectivo}
        onChangeMinima={this.onChangeMinima}
        handleAdminSelect={this.handleAdminSelect}
      />
    );
  }
}
