import React, { Component } from "react";
import SharePaso from "./share-paso";
import { ratingByObjectiveShare, checkFloatPercentage } from "./helper";
import { connect } from 'react-redux'

class SharePasoContainer extends Component {
  state = {
    rating: "N.A",
    objectivo: '',
    actual: ''
  };

  componentDidMount() {
    let { respuestas } = this.props.data;

    if (respuestas) {
      if (respuestas.length) {
        let inputs = JSON.parse(respuestas[0].answers);
        this.setState({
          rating: respuestas[0].rating,
          objectivo: inputs.objectivo,
          actual: inputs.actual
        });
      }
    }
  }

  onChangeObjectivo = e => {
    this.setState(
      checkFloatPercentage(e.currentTarget.value)
        ? {
            objectivo: e.currentTarget.value,
            rating: ratingByObjectiveShare(
              this.state.actual,
              parseFloat(e.currentTarget.value),
              this.props.tipo_agencia
            )
          }
        : {}
    );
  };

  onChangeActual = e => {
    this.setState(
      checkFloatPercentage(e.currentTarget.value)
        ? {
            actual: e.currentTarget.value,
            rating: ratingByObjectiveShare(
              parseFloat(e.currentTarget.value),
              this.state.objectivo,
              this.props.tipo_agencia
            )
          }
        : {}
    );
  };

  handleAdminSelect = e => {
    this.setState({
      rating: e.currentTarget.value,
    });
  }


  render() {
    
    return (
      <SharePaso
        data={this.props.data}
        rating={this.state.rating}
        objectivo={this.state.objectivo}
        actual={this.state.actual}
        onChangeObjectivo={this.onChangeObjectivo}
        onChangeActual={this.onChangeActual}
        tipo_agencia={this.props.tipo_agencia}
        handleAdminSelect={this.handleAdminSelect}
      />
    );
  }
}


const mapStateToProps = state => ({
  tipo_agencia: state.tipoStore.tipo_agencia
});

export default connect(mapStateToProps, {})(
  SharePasoContainer
);
