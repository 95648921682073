const ratingByObjective = (actual, objetive) => {
  let objectivo = parseInt(objetive, 10);
  return actual
    ? actual >= objectivo + 5
      ? "A"
      : objectivo + 5 > actual && objectivo -5 < actual
        ? "B"
        : "C"
    : "N.A";
};

const ratingByEbes = (actual, objetive) => {
  return actual
    ? actual > objetive * 1.05
      ? "A"
      : objetive * 1.05 > actual && objetive * 0.95 < actual
        ? "B"
        : "C"
    : "N.A";
};

const checkFloat = value => {
  const re = /^[1-9]\d*(\.)?[1-9]?$/;
  return (value === "") | re.test(value);
};

const checkFloatPercentage = value => {
  if (value > 100) {
    return false;
  }
  const re = /^[1-9]\d*(\.)?[1-9]?$/;
  return (value === "") | re.test(value);
};

const ratingByKpis = (value, data, estado_array) => {
  let rating = 0;
  let count_reales = 0;
  let new_object;
  let new_result = estado_array.map(k => {
    new_object =
      k.name === data.name ? Object.assign({}, k, { asnwser: value }) : k;

    rating +=
      new_object.asnwser === "A"
        ? 20
        : new_object.asnwser === "B"
          ? 15
          : new_object.asnwser === "C"
            ? 10
            : 0;

    count_reales =
      new_object.asnwser === "A" ||
      new_object.asnwser === "B" ||
      new_object.asnwser === "C"
        ? count_reales + 1
        : count_reales;
    return new_object;
  });

  let formula = rating / count_reales;
  let resultado =
    formula <= 20 && formula > 15.9
      ? "A"
      : formula <= 15.9 && formula > 12.9
        ? "B"
        : formula <= 12.9 && formula > 0
          ? "C"
          : "N.A";

  return {
    resultado: resultado,
    new_result: new_result
  };
};

const ratingByKpisInputs = inputs => {
  let rating = 0;
  let count_reales = 0;
  for (let i in inputs) {
    if (!i.search("rating_")) {
      rating +=
        inputs[i] === "A"
          ? 20
          : inputs[i] === "B"
            ? 15
            : inputs[i] === "C"
              ? 10
              : 0;

      count_reales =
        inputs[i] === "A" ||
        inputs[i] === "B" ||
        inputs[i] === "C"
          ? count_reales + 1
          : count_reales;
    }
  }
  let formula = rating / count_reales;
  let resultado =
    formula <= 20 && formula > 15.9
      ? "A"
      : formula <= 15.9 && formula > 12.9
        ? "B"
        : formula <= 12.9 && formula > 0
          ? "C"
          : "N.A";

  return resultado
};

module.exports = {
  ratingByObjective: ratingByObjective,
  checkFloat: checkFloat,
  checkFloatPercentage: checkFloatPercentage,
  ratingByKpis: ratingByKpis,
  ratingByEbes: ratingByEbes,
  ratingByKpisInputs: ratingByKpisInputs
};
