
export function fetchComentarios(page = 1, periodo = '', search = '') {
    return {
        types: ['LOADING_COMENTARIOS', 'COMENTARIOS_LIST_SUCCESS', 'FAIL'],
        payload: {
            request: {
                url: "/comentarios?page=" + page + "&periodo=" + periodo + "&search=" + search
            }
        }
    }
}
export function createComentario() {
    return {
        types: ['LOADING_COMENTARIO_CREATE', 'COMENTARIO_CREATE_SUCCESS', 'FAIL'],
        payload: {
            request: {
                url: '/comentarios/create',
            }
        }
    }
}

export function saveComentario(categoria) {
    return {
        type: 'SAVE_COMENTARIO',
        payload: {
            request: {
                method: 'POST',
                url: '/comentarios',
                data: categoria
            }
        }
    }
} 