import {
    LOADING_MARCAS,
    MARCAS_LIST_SUCCESS,
    FAIL,
} from '../constants';

export function fetchMarcas(value = ""){
    return {
        types : [LOADING_MARCAS, MARCAS_LIST_SUCCESS, FAIL],
        payload : {
            request : {
                url : "/marcas?value=" + value
            }
        }
    }
}

export function createMarca(){
  return {
    type : 'CREATE_MARCA',
    payload :{
      request : {
        url: '/marcas/create',
      }
    }
  }
}

export function saveMarca(marca) {
  return {
    type : 'SAVE_MARCA',
    payload :{
      request : {
        method: 'POST',
        url: '/marcas',
        data: marca
      }
    }
  }
}

export function fetchMarca(_name) {
  return {
    type : 'FETCH_MARCA',
    payload :{
      request : {
        url: '/marcas/' + _name + '/edit',
      }
    }
  }
}

export function updateMarca(marca) {
  return {
    type : 'UPDATE_MARCA',
    payload :{
      request : {
        method : 'PUT',
        url: '/marcas/' + marca.name,
        data: marca
      }
    }
  }
}

export function deleteMarca(_name) {
  return {
    type : 'DELETE_MARCA',
    payload :{
      request : {
        method : 'DELETE',
        url: '/marcas/' + _name,
      }
    }
  }
}

/* 



export function deleteContact(_id) {
  return dispatch => {
    return dispatch({
      type: 'DELETE_CONTACT',
      payload: client.delete(`${url}/${_id}`)
    })
  }
} 

*/