import React, { Component } from "react";

export default class Comentario extends Component {

  render() {
    const disabled = JSON.parse(localStorage.getItem('user')).role === 'marketing' ? '' : 'disbaled';
    return (
      <section className="pasos">
        <h3>Comentarios </h3>
        <span className="text-mini">Por favor deje su comentario sobre la evaluación.</span>
        <div>
          <textarea
            name="comentario"
            disabled={disabled}
            className="comentario"
            id=""
            cols="30"
            rows="10"
            defaultValue={this.props.describe}></textarea>
        </div>
      </section>
    );
  }
}
