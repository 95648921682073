import {
    LOADING_CATEGORIA_MARCAS,
    CATEGORIA_MARCAS_LIST_SUCCESS,
    FETCH_CATEGORIA_MARCA,
    FETCH_CATEGORIA_MARCA_SUCCESS,
    SAVE_CATEGORIA_MARCA_SUCCESS,
    UPDATE_CATEGORIA_MARCA_SUCCESS,
} from '../constants';

const initialState = {
    loading_categoria_marcas : true,
    categoria_marcas : [],
    categoria_marca : {}
}


export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_CATEGORIA_MARCAS:
            return Object.assign({}, state,{
                loading_categoria_marcas: true
            });

        case CATEGORIA_MARCAS_LIST_SUCCESS:
            return Object.assign({}, state,{
                categoria_marcas : action.payload.data,
                loading_categoria_marcas: false
            })
        case FETCH_CATEGORIA_MARCA:
            return Object.assign({}, state, {
                categoria_marca: {}
            })
        case FETCH_CATEGORIA_MARCA_SUCCESS:
            return Object.assign({}, state, {
                categoria_marca: action.payload.data
            })
        case SAVE_CATEGORIA_MARCA_SUCCESS: 
            return Object.assign({}, state, {
                categoria_marca: action.payload.data
            })
        case UPDATE_CATEGORIA_MARCA_SUCCESS:
            return Object.assign({}, state, {
                categoria_marca: action.payload.data
            }) 
        case 'DELETE_CATEGORIA_MARCA_SUCCESS':
            return Object.assign({}, state, {
                categoria_marcas : state.categoria_marcas.filter( e => e.name !== action.payload.data.name)
            })
        default:
            return state;
    }
}