import React from "react";

const RowResult = props => (
  <tr className="table-line table-top">
    <td className="bottom-hidden top-hidden" />
    <td>
      <h3>{props.title}</h3>
    </td>
    <td className="border-right">
      <input
        type="text"
        name={props.name}
        className="consolidate_input"
        onChange={e => e}
        value={props.value }
      />
    </td>
  </tr>
);

export default RowResult;
