import React, { Component } from "react";
import { connect } from "react-redux";
/* import { Link } from "react-router-dom"; */

import Content from "routes/_hoc/Content";
import ClearValues from "./components/clear-values";
import ListConsolidado from "./components/list-consolidado";
import { fetchConsolidados } from "modules/consolidado/consolidado-actions";

class ListConsolidadoPage extends Component {
  state = {
    page: 1,
    periodo: "",
    tipo: "",
    agencia: ""
  };

  componentDidMount() {
    this.props.fetchConsolidados();
  }

  handlePageClick = page => {
    this.props.fetchConsolidados('', '', '', page.selected + 1).then(e => {
      this.setState({
        page: page.selected + 1
      });
    });
  };

  onClickAgencia = agencia => {
    this.props
      .fetchConsolidados(agencia, this.state.tipo, this.state.periodo, 1)
      .then(e => {
        this.setState({
          agencia: agencia,
          page: 1
        });
      });
  };
  onClickTipo = tipo => {
    this.props
      .fetchConsolidados(this.state.agencia, tipo, this.state.periodo, 1)
      .then(e => {
        this.setState({
          tipo: tipo,
          page: 1
        });
      });
  };

  onClickPeriodo = periodo => {
    this.props
      .fetchConsolidados(this.state.agencia, this.state.tipo, periodo, 1)
      .then(e => {
        this.setState({
          periodo: periodo,
          page: 1
        });
      });
  };

  onClean = e => {
    this.props.fetchConsolidados().then(e => {
      this.setState({
        page: 1,
        periodo: "",
        tipo: "",
        agencia: ""
      });
    });
  };

  render() {
    return (
      <section>
        <ClearValues
          agencia={this.state.agencia}
          tipo={this.state.tipo}
          periodo={this.state.periodo}
          onClean={this.onClean}
        />
        <ListConsolidado
          loading={this.props.loading}
          consolidados={this.props.consolidados}
          onClickAgencia={this.onClickAgencia}
          onClickTipo={this.onClickTipo}
          onClickPeriodo={this.onClickPeriodo}
          handlePageClick={this.handlePageClick}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  consolidados: state.consolidadoStore.consolidados,
  loading: state.consolidadoStore.loading_consolidados
});

export default connect(mapStateToProps, { fetchConsolidados })(
  Content(ListConsolidadoPage, {
    title: "Listado de Consolidados",
    links: [{ title: "Consolidados" }]
  })
);
