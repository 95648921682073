import React, { Component } from "react";
import FormUser from "./components/form-user";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  saveUser,
  fetchUser,
  updateUser,
  createUser
} from "modules/users/user-actions";

class FormUserPage extends Component {
  state = {
    user: {},
    redirect: false,
    selectedOption: ""
  };

  componentDidMount() {
    let { _id } = this.props.match.params;
    let ambito = this;
    if (_id) {
      this.props.fetchUser(_id).then(e => {
        if (ambito.props.user.role !== "admin") {
          console.log(ambito.props.user);
          ambito.setState({ selectedOption:  {id :"marketing"} });
        }
      });
    } else {
      this.props.createUser();
    }
  }

  handleChange = selectedOption => {
    this.setState({
      selectedOption
    });
  };
  handleSubmit = user => {
    let ambito = this;
    if (!user.id) {
      return this.props.saveUser(user).then(function(e) {
        ambito.setState({
          redirect: true
        });
      });
    } else {
      return this.props.updateUser(user).then(function(e) {
        ambito.setState({
          redirect: true
        });
      });
    }
  };

  render() {
    let { _id } = this.props.match.params;
    let { user } = this.props;

    let breadcrumb = [
      { title: "Listado de Usuarios", to: "/users" },
      { title: _id ? user.name : "Nuevo Usuario" }
    ];

    let title = _id ? "Editar Usuario" : "Nueva Usuario";
    let button_message = _id ? "Actualizar" : "Registrar";

    return this.state.redirect ? (
      <Redirect to="/users" />
    ) : (
      <FormUser
        onSubmit={this.handleSubmit}
        title={title}
        data={user}
        links={breadcrumb}
        handleChange={this.handleChange}
        button_message={button_message}
        selectedOption={this.state.selectedOption}
      />
    );
  }
}

/* export default FormUserPage; */

const mapStateToProps = state => ({
  user: state.userStore.user,
});

export default connect(mapStateToProps, {
  saveUser,
  fetchUser,
  updateUser,
  createUser
})(FormUserPage);
