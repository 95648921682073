import {
    LOADING_USERS,
    USERS_LIST_SUCCESS,
    FETCH_USER,
    FETCH_USER_SUCCESS,
    SAVE_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
} from './constants';

const initialState = {
    loading_users: true,
    users: [],
    user: {}
}


export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_USERS:
            return Object.assign({}, state, {
                loading_users: true
            });

        case USERS_LIST_SUCCESS:
            return Object.assign({}, state, {
                users: action.payload.data,
                loading_users: false
            })
        case 'CREATE_USER_SUCCESS':
            return Object.assign({}, state, {
                selected_marcas: action.payload.data
            })
        case FETCH_USER:
            return Object.assign({}, state, {
                user: {}
            })
        case FETCH_USER_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload.data.user,
            })
        case SAVE_USER_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload.data
            })
        case UPDATE_USER_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload.data
            })
        case 'DELETE_USER_SUCCESS':
            return Object.assign({}, state, {
                users: {
                    ...state.users,
                    data: state.users.data.filter(e => e.name !== action.payload.data.name)
                }
            })
        default:
            return state;
    }
}