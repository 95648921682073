import React from "react";
import Select from "react-select";

export const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) =>
  type !== "hidden" ? (
    <div className="form-group">
      <label>{label}</label>
      <div>
        <input {...input} placeholder={label} type={type} />
      </div>
      {touched &&
        ((error && <span className="error-input">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  ) : (
    <div />
  );
export const renderTextarea = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) =>
  type !== "hidden" ? (
    <div className="form-group">
      <label>{label}</label>
      <div>
        <textarea {...input} placeholder={label} type={type} className="comentario"
          cols="30"
          rows="10">{input.value}</textarea>
      </div>
      {touched &&
        ((error && <span className="error-input">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  ) : (
    <div />
  );
export const renderCheckbox = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) =>
  type !== "hidden" ? (
    <div className="form-group">
      <span>{label}</span>
      <label>
        <input {...input} placeholder={label} type={type} className="checkbox" />
        <div className="circle-cover-checkbox">
          <div className="circle-checkbox"></div>
        </div>
      </label>
      {touched &&
        ((error && <span className="error-input">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  ) : (
    <div />
  );

export const SelectField = ({
  input,
  options,
  name,
  id,
  label,
  initialValues,
  meta: { touched, error, warning }
}) => {
  return (
    <div className="form-group field">
      <label className="label">{label}</label>
      <div className="control">
        <div
          className={
            "select " + (touched ? (error ? "is-danger" : "is-success") : "")
          }
        >
          <Select
            {...input}
            value={input.value}
            id={id}
            name={name}
            options={options}
            labelKey="description"
            valueKey="id"
            multi
            clearable={false}
            onBlur={() => input.onBlur(input.value)}
            onBlurResetsInput={false}
          />
          {touched && (error && <p className="help is-danger">{error}</p>)}
        </div>
      </div>
    </div>
  );
};



export const SelectSimpleField = ({
  input,
  options,
  name,
  id,
  label,
  initialValues,
  meta: { touched, error, warning }
}) => {
  return (
    <div className="form-group field">
      <label className="label">{label}</label>
      <div className="control">
        <div
          className={
            "select " + (touched ? (error ? "is-danger" : "is-success") : "")
          }
        >
          <Select
            {...input}
            value={input.value}
            id={id}
            name={name}
            options={options}
            labelKey="description"
            valueKey="id"
            clearable={false}
            onBlur={() => input.onBlur(input.value)}
            onBlurResetsInput={false}
          />
          {touched && (error && <p className="help is-danger">{error}</p>)}
        </div>
      </div>
    </div>
  );
};
