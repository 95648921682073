import React from "react";

export default props => {
  return (
    <tr className="table-line">
      <td>{props.data.objective}</td>
      <td>{props.data.measurement_unit}</td>
      {props.data.respuestas.map( (e,i) => (
        <td className="text-center" key={"respuesta_" + i}>
          {e ? e : 'N.A'} 
        </td>
      ))}
    </tr>
  );
};
