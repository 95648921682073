import React from 'react'
import logo from "../_assets/user.svg";

export default function User(props){
    const usuario = JSON.parse(localStorage.getItem('user'));
    return (
        <div className="logged-user-w">
            <div className="logged-user-i">
                <div className="avatar-w">
                    <img alt="usuario" src={logo} />
                </div>
            
                <div className="logged-user-menu">
                    <div className="logged-user-avatar-info">
                    <div className="avatar-w">
                        <img alt="" src="" />
                    </div>
                    <div className="logged-user-info-w">
                        <div className="logged-user-name">{ usuario.name }</div>
                        <div className="logged-user-role">{ usuario.role === 'admin' ? 'Administrador' : 'Marketing' }</div>
                    </div>
                    </div>
        
                </div>
            </div>
      </div>
    )
}
