import React, { Component } from "react";
import Logo from "./components/Logo";
import User from "./components/User";
import Menu from "./components/Menu";
import ModalError from "./routes/_portals/ModalError";
import Rutas from "./routes/routes";
import { logout } from "modules/login/login-actions";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
class App extends Component {
  onHandleHideMenu = e => {
    let aside = document.getElementById("menuAside");
    let background = document.getElementById("masterBlackMenu");
    background.style.display = "none";
    aside.style.display = "none";
  };

  handleSalir = e => {
    this.props.logout().then(e => {
      localStorage.clear();
      window.location.replace("/login");
    });
  };

  render() {
    const token = localStorage.getItem("token");

    const aside = token ? (
      <section
        className="desktop-menu menu-side-w menu-activated-on-click"
        id="menuAside"
      >
        <Logo />
        <User />
        <nav>
          <Menu
            handleSalir={this.handleSalir}
            role={localStorage.getItem("role")}
          />
        </nav>
      </section>
    ) : (
      ""
    );

    return (
      <div
        className={
          token
            ? "all-wrapper menu-side with-side-panel"
            : "menu-side with-side-panel"
        }
      >
        <div className="layout-w">
          <section
            className="master-black-menu"
            id="masterBlackMenu"
            onClick={this.onHandleHideMenu}
          />
          {aside}
          <section className={token ? "content-w" : "extends"}>
            <Rutas />
          </section>
        </div>
        <ModalError />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.loginStore.data
});


export default withRouter(connect(mapStateToProps, { logout })(App));
