import React, { Component } from "react";
import { Link } from "react-router-dom";


export default class Menu extends Component {
  render() {
    const { role } = this.props;
    return (
      <ul className="main-menu">
        {role === "marketing" ? (
          <li className="has-sub-menu">
            <Link to="/">
              <div className="icon-w">
                <div className="os-icon os-icon-hierarchy-structure-2" />
              </div>
              <span>Evaluar Agencias</span>
            </Link>
          </li>
        ) : (
          ""
        )}

        {role === "admin" ? (
          <li className="has-sub-menu">
            <Link to="/agencias">
              <div className="icon-w">
                <div className="os-icon os-icon-hierarchy-structure-2" />
              </div>
              <span>Agencias</span>
            </Link>
          </li>
        ) : (
          ""
        )}

        {role === "admin" ? (
          <li className="has-sub-menu">
            <Link to="/marcas">
              <div className="icon-w">
                <div className="os-icon os-icon-hierarchy-structure-2" />
              </div>
              <span>Marcas</span>
            </Link>
          </li>
        ) : (
          ""
        )}

        {role === "admin" ? (
          <li className="has-sub-menu">
            <Link to="/paises">
              <div className="icon-w">
                <div className="os-icon os-icon-hierarchy-structure-2" />
              </div>
              <span>Países</span>
            </Link>
          </li>
        ) : (
          ""
        )}

        {role === "admin" ? (
          <li className="has-sub-menu">
            <Link to="/categoria-marcas">
              <div className="icon-w">
                <div className="os-icon os-icon-hierarchy-structure-2" />
              </div>
              <span>Categorias de Marca</span>
            </Link>
          </li>
        ) : (
          ""
        )}

        {role === "admin" ? (
          <li className="has-sub-menu">
            <Link to="/periodos">
              <div className="icon-w">
                <div className="os-icon os-icon-hierarchy-structure-2" />
              </div>
              <span>Periodos</span>
            </Link>
          </li>
        ) : (
          ""
        )}

        {role === "admin" ? (
          <li className="has-sub-menu">
            <Link to="/users">
              <div className="icon-w">
                <div className="os-icon os-icon-hierarchy-structure-2" />
              </div>
              <span>Usuarios</span>
            </Link>
          </li>) : (
          ""
        )}

        {role === "marketing" || role === "admin" ? (
          <li className="has-sub-menu">
            <Link to="/historial">
              <div className="icon-w">
                <div className="os-icon os-icon-delivery-box-2" />
              </div>
              <span>Historial</span>
            </Link>
          </li>) : (
          ""
        )}

        {role === "admin" ? (
          <li className="has-sub-menu">
            <Link to="/logs">
              <div className="icon-w">
                <div className="os-icon os-icon-delivery-box-2" />
              </div>
              <span>Logs</span>
            </Link>
          </li>) : (
          ""
        )}

        {role === "admin" ? (
          <li className="has-sub-menu">
            <Link to="/consolidados">
              <div className="icon-w">
                <div className="os-icon os-icon-delivery-box-2" />
              </div>
              <span>Consolidados</span>
            </Link>
          </li>) : (
          ""
        )}

        {role === "marketing" || role === "admin" ? (
          <li className="has-sub-menu">
            <Link to="/comentarios">
              <div className="icon-w">
                <div className="os-icon os-icon-hierarchy-structure-2" />
              </div>
              <span>Comentarios</span>
            </Link>
          </li>
        ) : (
          ""
        )}

        <li className="has-sub-menu">
          <a onClick={this.props.handleSalir} href='#out'>
            <div className="icon-w">
              <div className="os-icon os-icon-delivery-box-2" />
            </div>
            <span>Salir</span>
          </a>
        </li>
      </ul>
    );
  }
}
