import React from 'react'
import { Link } from 'react-router-dom';

export default function Breadcrumb(props){
  
    
    let restante = props.links.map( (e, i) => {
      return e.to ? <li className="breadcrumb-item" key={'breadcrumb-' +  i}>
        <Link to={'/' + e.to}>{e.title}</Link>
      </li> : 
      <li className="breadcrumb-item" key={'breadcrumb-' +  i}>
        <span>{e.title}</span>
      </li>;
    });

    return (
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>          
          {restante}
        </ul>
      </div>
    )
  
}
