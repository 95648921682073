import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form'


import evaluacion from "modules/evaluacion/reducers/evaluacion-reducers";
import loginStore from './login/login-reducers'
import filterStore from './filters/filter-reducers'
import consolidadoStore from "modules/consolidado/consolidado-reducers";
import fichaStore from "modules/ficha/ficha-reducers";
import pasoStore from "./evaluacion/reducers/pasos-reducers";
import modal from "modules/_portal/modal-reducer";
import tipoStore from "modules/evaluacion/reducers/tipo-reducer";
import analisisStore from "modules/evaluacion/reducers/analisis-reducers";
import marcaStore from "modules/marca/reducers/marca-reducers";
import paisesStore from "modules/paises/reducers/paises-reducers";
import periodoStore from "modules/periodo/reducers/periodo-reducers";
import agenciaStore from "modules/agencia/reducers/agencia-reducers";
import categoriaMarcaStore from "modules/categoria-marca/reducers/categoria-marca-reducers";
import userStore from "modules/users/user-reducers";
import logStore from "modules/logs/logs-reducers";
import comentariosStore from "modules/comentarios/comentarios-reducers";

const rootReducer = combineReducers({
  evaluacion,
  modal,
  tipoStore,
  analisisStore,
  marcaStore,
  paisesStore,
  periodoStore,
  agenciaStore,
  pasoStore,
  fichaStore,
  userStore,
  consolidadoStore,
  categoriaMarcaStore,
  loginStore,
  filterStore,
  logStore,
  comentariosStore,
  form: formReducer
});

export default rootReducer;
