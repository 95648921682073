import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField } from "routes/_helpers/campos";
import validate from "./helpers/validate";
import Loading from "components/Loading";
const FormLogin = props => {
  const { error, handleSubmit, pristine, submitting, loading } = props;
  return (
    <form onSubmit={handleSubmit}>
      <h3 className="element-header">Iniciar Sesión</h3>
      { loading ? <Loading /> : ''}
      <p className="error-input">
        {error && <strong>{error}</strong>}
      </p>
      <Field
        type="text"
        component={renderField}
        name="email"
        id="email"
        label="Email"
        placeholder="Ingresar su email"
      />

      <Field
        type="password"
        component={renderField}
        name="password"
        id="password"
        label="Contraseña"
        placeholder="Ingresar su contraseña"
      />

      <div className="buttons-w group-buttons-w">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={pristine || submitting}
        >
          Ingresar
        </button>
        <div className="form-check-inline">
          <label className="form-check-label">
            <input className="form-check-input" type="checkbox" />Recordarme
          </label>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({ form: "login", validate })(FormLogin);
