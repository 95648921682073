import React from "react";

const RowCalificacion = props => (
  <tr className="table-line">
    <td className="bottom-hidden" />
    <td style={{ background: props.indice === 0 ? "#fff818" : "" }}>{props.data.name}</td>
    {props.data.result.map((k, i) => (
      <td
        key={"clave_clasificacion_" + i}
        style={{ background: props.indice === 0 ? "#fff818" : "" }}
        className="text-center"
      >
        {k.resp + (props.indice === 2 ? "%": "")}
      </td>
    ))}
  </tr>
);

export default RowCalificacion;
