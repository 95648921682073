import React, { Component } from "react";
import FormPais from "./components/form-pais";
import {  Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { savePais , fetchPais, updatePais } from "modules/paises/actions/paises-actions";

class FormPaisPage extends Component {
  state = {
    pais: {},
    redirect: false
  };

  componentDidMount() {    
    let { _id } = this.props.match.params;
    if (_id) {
        this.props.fetchPais(_id);
    } 
  }

  handleSubmit = (pais) => {
    let ambito = this;
    if(!pais.id){
      return this.props.savePais(pais).then(function(e){
        ambito.setState({
          redirect: true
        });  
      });

    }else{
      return this.props.updatePais(pais).then(function(e){
        ambito.setState({
          redirect: true
        });  
      });
    }
  }


  render() {
    let { _name } = this.props.match.params;
    let { pais } = this.props;

    let breadcrumb = [
      { title: "Países", to: "/paises" },
      { title: _name ? _name : "Nuevo Pais" }
    ];

    let title = _name ? "Editar Pais" : "Nueva Pais";
    let button_message = _name ? 'Actualizar' : 'Registrar';

    return (
      this.state.redirect ? <Redirect  to='/paises' />: 
      <FormPais
        onSubmit={this.handleSubmit}
        title={title}
        data={pais} 
        links={breadcrumb}
        button_message={button_message}
      />
    );
  }
}

/* export default FormPaisPage; */

const mapStateToProps = state => ({
  pais: state.paisesStore.pais, 
});


export default connect(mapStateToProps, { savePais, fetchPais, updatePais })(FormPaisPage);
