import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Selection from "./components/selection";

import * as TodoActions from "modules/evaluacion/actions/evaluacion-actions";

class Evaluacion extends Component {
  componentDidMount() {
    if (localStorage.getItem("tipo_selected") !== this.props.tipo_selected) {
      localStorage.setItem('selected_agencia', '');
      localStorage.setItem('selected_marca', '');
      localStorage.setItem('selected_periodo', ''); 
      localStorage.setItem('selected_pais', ''); 
      this.props.actions.cleanForm();
    }
    
    if(!localStorage.getItem('selected_agencia') && 
        !localStorage.getItem('selected_marca') && 
        !localStorage.getItem('selected_pais') && 
        !localStorage.getItem('selected_periodo')){
          if(this.props.tipo_selected){
            localStorage.setItem("tipo_selected", this.props.tipo_selected);
          }
            this.props.actions.loadPaises();

    } 
  }

  onClickIniciar = e => {
    e.preventDefault();
     let {
      selected_agencia,
      selected_marca,
      selected_periodo,
      selected_pais
    } = this.props.agencias;

    localStorage.setItem('selected_pais', selected_pais);
    localStorage.setItem('selected_agencia', selected_agencia);
    localStorage.setItem('selected_marca', selected_marca);
    localStorage.setItem('selected_periodo',selected_periodo ); 

    this.props.history.push({
      pathname: "/evaluacion/" + this.props.agencias.selected_agencia
    });

  };

  render() {
    const {
      agencias,
      marcas,
      periodos,
      paises,
      loading_agencias,
      loading_marcas,
      loading_periodos,
      loading_paises,
      selected_agencia,
      selected_marca,
      selected_periodo,
      selected_pais
    } = this.props.agencias;
    return (
      <section>
        <Selection
          agencias={agencias}
          periodos={periodos}
          marcas={marcas}
          agencia_active={loading_agencias}
          periodo_active={loading_periodos}
          marca_active={loading_marcas}
          selected_agencia={selected_agencia}
          selected_marca={selected_marca}
          selected_periodo={selected_periodo}
          actions={this.props.actions}
          onClickIniciar={this.onClickIniciar}
          links={[{ title: "Evaluar " + localStorage.getItem("tipo_selected") }]}
          title={"Evaluar " + localStorage.getItem("tipo_selected")}
          
          paises={paises}
          paises_active={loading_paises}
          selected_pais={selected_pais}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  agencias: state.evaluacion,
  modal: state.modal,
  tipo_selected: state.tipoStore.tipo_agencia
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(TodoActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Evaluacion);
