import {
    LOADING_PASOS,
    PASOS_SUCCESS,
    CHANGE_POSITION,
    CHANGE_ANWSER_OBJECTIVE,
    CHANGE_ANWSER_ACTUAL
} from '../constants'

const initialState =  {
    pasos : [],
    loading_pasos: true,
    current_step: 1,
    evaluacion : {}
}

export default function analisis(state = initialState, action) {
    switch (action.type) {
        case LOADING_PASOS: 
            return Object.assign({}, state,{
                loading_pasos: true
            })
        case PASOS_SUCCESS:
            return Object.assign({}, state,{
                pasos: action.payload.data.pasos,
                evaluacion: action.payload.data.evaluacion,
                loading_pasos: false,
                current_step: action.payload.data.evaluacion ? action.payload.data.evaluacion.current_step: 1
            })
        case CHANGE_POSITION:
            return Object.assign({}, state,{
                current_step : action.position
            })
        case CHANGE_ANWSER_OBJECTIVE:
            return Object.assign({}, state,{
                pasos: state.pasos.map(e => e.id === action.pregunta_id ? e.n_objective : '' )
            })
        case CHANGE_ANWSER_ACTUAL:
            return Object.assign({}, state,{
                pasos: action.pasos
            })
        default:
            return state
    }
}