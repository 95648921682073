import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {
  renderField,
  SelectSimpleField,
  renderCheckbox
} from "routes/_helpers/campos";
import validate from "../helpers/validate-user";

import CrudContent from "../../_hoc/CrudContent";

class FormUser extends Component {
  componentWillReceiveProps = nextProps => {
    const { data } = nextProps;

    if (data.name !== this.props.data.name) {
      this.props.initialize(data);
    }
  };

  render() {
    const { handleSubmit, submitting, button_message } = this.props;

    return (
      <form onSubmit={handleSubmit} className="formulario-crud element-box">
        <p>Ingrese los datos de la Usuario</p>
        <br />

        <Field
          component={SelectSimpleField}
          name="role"
          label="Tipo de usuario"
          onChange={this.props.handleChange}
          options={[
            { id: "marketing", description: "Marketing" },
            { id: "admin", description: "Administrador" }
          ]}
          id="role"
        />
        <Field
          type="text"
          component={renderField}
          name="name"
          id="name"
          label="Nombre"
          placeholder="Ingresar nombre"
        />

        <Field
          type="email"
          component={renderField}
          name="email"
          id="email"
          label="Email"
          placeholder="Ingresar email"
        />

        <Field
          type="password"
          component={renderField}
          name="password"
          id="password"
          label="Password"
          placeholder="Ingresar Contraseña"
        />


        <Field
          type="checkbox"
          component={renderCheckbox}
          name="state"
          id="state"
          label="Estado"
        />

        <div className="top-line">
          <Link to="/users" className="btn btn-black">
            Regresar
          </Link>
          <button
            type="submit"
            disabled={submitting}
            className="btn btn-primary left-15"
          >
            {button_message}
          </button>
        </div>
      </form>
    );
  }
}

export default CrudContent(reduxForm({ form: "user", validate })(FormUser));
