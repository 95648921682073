import React from "react";
import GoSearch from "react-icons/lib/go/search";

class Search extends React.PureComponent {
  state = {
    value: "",
  };

  onChange = e => {
    this.setState({
      value: e.currentTarget.value
    });
  };

  onSubmit = e => {
      e.preventDefault();

      this.props.handleSearch( this.state.value);
  };

 
  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <div className="search-group">
          <input
            type="text"
            className="form-control search-input"
            name="q"
            value={this.state.value}
            onChange={this.onChange}
            placeholder="Buscar por..."
          />
          <button type="submit" className="search-icon">
            <GoSearch />
          </button>
          
        </div>
      </form>
    );
  }
}

export default Search;
