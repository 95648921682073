const initialState = {
    loading_logs: false,
    logs: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "LOADING_LOGS":
            return Object.assign({}, state, {
                loading_logs: true
            });

        case "LOGS_SUCCESS":
            return Object.assign({}, state, {
                logs: action.payload.data,
                loading_logs: false
            });

        default:
            return state;
    }
};