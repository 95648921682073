
/*
SELECCIÓN DE TIPO DE AGENCIA
*/
export const TIPO_SELECTED = 'TIPO_SELECTED'
export const LOADING_TIPOS = 'LOADING_TIPOS'
export const TIPOS_SUCCESS = 'TIPOS_SUCCESS'

/*
SELECCIÓN DE EVALUACIÓN
*/
export const SELECTED_AGENCIA = 'SELECTED_AGENCIA'
export const SELECTED_MARCA = 'SELECTED_MARCA'
export const SELECTED_PERIODO = 'SELECTED_PERIODO'

export const LOADING_AGENCIA = 'LOADING_AGENCIA'
export const LOADING_MARCA = 'LOADING_MARCA'
export const LOADING_PERIODO = 'LOADING_PERIODO'

export const AGENCIAS_SUCCESS = 'AGENCIAS_SUCCESS'
export const MARCAS_SUCCESS = 'MARCAS_SUCCESS'
export const PERDIODOS_SUCCESS = 'PERDIODOS_SUCCESS'

/*
MODAL
*/
export const FAIL = 'FAIL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

/*
ANALISIS
*/
export const LOADING_PASOS = 'LOADING_PASOS'
export const PASOS_SUCCESS = 'PASOS_SUCCESS'
export const CHANGE_POSITION = 'CHANGE_POSITION'
export const CHANGE_ANWSER_OBJECTIVE = 'CHANGE_ANWSER_OBJECTIVE'
export const CHANGE_ANWSER_ACTUAL = 'CHANGE_ANWSER_ACTUAL'
export const CHANGE_ANWSER_SELECT = 'CHANGE_ANWSER_SELECT'


/*
CONSOLIDADO
*/


/*
AGENCIAS
*/
export const LOADING_AGENCIAS = 'LOADING_AGENCIAS';
export const AGENCIAS_LIST_SUCCESS = 'AGENCIAS_LIST_SUCCESS';
/*
MARCAS
*/
export const LOADING_MARCAS = 'LOADING_MARCAS';
export const MARCAS_LIST_SUCCESS = 'MARCAS_LIST_SUCCESS';
/*
PERIODOS
*/
export const LOADING_PERIODOS = 'LOADING_PERIODOS';
export const PERIODOS_LIST_SUCCESS = 'PERIODOS_LIST_SUCCESS';


export const LOADING_PAISES = 'LOADING_PAISES'
export const PAISES_SUCCESS = 'PAISES_SUCCESS'
export const SELECTED_PAIS = 'SELECTED_PAIS'
