import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {
  renderField, 
  renderCheckbox
} from "routes/_helpers/campos"; 
import validate from "../helpers/validate-pais";

import CrudContent from "routes/_hoc/CrudContent";

class FormPais extends Component {
  componentWillReceiveProps = nextProps => {
    const { data } = nextProps;
 

    if (data.nombre !== this.props.data.nombre) {
      this.props.initialize(data);
    }
  };

  render() {
    const { handleSubmit, submitting, button_message } = this.props;

    return (
      <form onSubmit={handleSubmit} className="formulario-crud element-box">
        <p>Ingrese los datos del pais</p>
        <br />

        <Field
          type="text"
          component={renderField}
          name="nombre"
          id="nombre"
          label="Nombre"
          placeholder="Ingresar nombre del pais"
        />

 
        <Field
          type="checkbox"
          component={renderCheckbox}
          name="estado"
          id="estado"
          label="Estado"
        />

        <div className="top-line">
          <Link to="/paises" className="btn btn-black">
            Regresar
          </Link>
          <button
            type="submit"
            disabled={submitting}
            className="btn btn-primary left-15"
          >
            {button_message}
          </button>
        </div>
      </form>
    );
  }
}

export default CrudContent(reduxForm({ form: "pais", validate })(FormPais));
/*
           */
