
const initialState = {
    loading_comentarios: true,
    comentarios: [],
    loading_comentario: false,
    paises: [],
    agencias: [],
    marcas: [],
    periodos: [],
}


export default (state = initialState, action) => {
    switch (action.type) {
        case 'LOADING_COMENTARIOS':
            return Object.assign({}, state, {
                loading_comentarios: true
            });

        case 'COMENTARIOS_LIST_SUCCESS':
            return Object.assign({}, state, {
                comentarios: action.payload.data,
                loading_comentarios: false
            })
        case 'LOADING_COMENTARIO_CREATE':
            return Object.assign({}, state, {
                paises: [],
                agencias: [],
                marcas: [],
                periodos: [],
                loading_comentario: true
            })
        case 'COMENTARIO_CREATE_SUCCESS':
            return Object.assign({}, state, {
                paises: action.payload.data.paises,
                agencias: action.payload.data.agencias,
                marcas: action.payload.data.marcas,
                periodos: action.payload.data.periodos,
                loading_comentario: false
            });
        case 'SAVE_COMENTARIO_LOADING':
            return Object.assign({}, state, {
                loading_comentario: true,
            })
        case 'SAVE_COMENTARIO_SUCCESS':
            return Object.assign({}, state, {
                loading_comentario: false,
            })

        default:
            return state;
    }
}