import React, { Component } from "react";
import Consolidado from "./components/consolidado";
import { connect } from "react-redux";
import swal from "sweetalert";
import Loading from "components/Loading";

import {
  consolidado,
  saveConsolidado,
  consolidadoAnual
} from "modules/consolidado/consolidado-actions";

class ConsolidadoPage extends Component {
  componentDidMount() {
    let { _id } = this.props.match.params;
    if(this.props.match.path.search("/consolidado-anual") !== -1)
        this.props.consolidadoAnual(_id)
    else 
        this.props.consolidado(_id)
     
  }

  onHandleUpdate = e => {
    return e;
  };

  serilizeForm(selector) {
    var dictionary = {};
    // var form = window.document.querySelector(selector);
    var formdata = new FormData(selector);
    var done = false;
    var iterator = formdata.entries();
    do {
      var prop = iterator.next();
      if (prop.done && !prop.value) {
        done = true;
      } else {
        dictionary[prop.value[0]] = prop.value[1];
      }
    } while (!done);
    return dictionary;
  }

  handleSubmit = e => {
    e.preventDefault();
    let { _id } = this.props.match.params;
    let data = this.serilizeForm(e.currentTarget);
    let ambito = this;
    swal({
      title: "Estás Seguro?",
      icon: "warning",
      buttons: true
    }).then(accept => {
      if (accept) {
        ambito.props.saveConsolidado(_id, data).then(e => {
          if (e.type === "SAVE_CONSOLIDADO_SUCCESS") {
            swal("Consolidado Registrado con Éxito", {
              icon: "success"
            });
          }
        });
      }
    });
  };
  render() {
    const { pasos, marcas, resultados, loading, consolidate } = this.props;
    const anual = this.props.match.path.search("/consolidado-anual") !== -1 ? 1 : 0;

    return this.props.loading ? (
      <Loading />
    ) : (
      <Consolidado
        links={[{ title: "Consolidado" }]}
        title={"Consolidado"}
        onHandleUpdate={this.onHandleUpdate}
        pasos={pasos}
        marcas={marcas}
        loading={loading}
        resultados={resultados}
        consolidado={consolidate}
        handleSubmit={this.handleSubmit}
        anual={anual}
      />
    );
  }
}

const mapStateToProps = state => ({
  pasos: state.consolidadoStore.pasos,
  marcas: state.consolidadoStore.marcas,
  loading: state.consolidadoStore.loading_pasos,
  resultados: state.consolidadoStore.resultados,
  consolidate: state.consolidadoStore.consolidate
});

export default connect(mapStateToProps, { consolidado, saveConsolidado, consolidadoAnual })(
  ConsolidadoPage
);
