import { TIPO_SELECTED,
    LOADING_TIPOS,
    TIPOS_SUCCESS} 
    from '../constants'

const initialState = {
    tipos : [],
    loading_tipos : false,
    tipo_agencia :  localStorage.getItem('tipo_selected') ? localStorage.getItem('tipo_selected') : ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TIPOS:
      return Object.assign({}, state, {
        loading_tipos: true
    });
    case TIPOS_SUCCESS:
        return Object.assign({}, state, {
            tipos : action.payload.data,
            loading_tipos: false
        });
    case TIPO_SELECTED:
        return Object.assign({}, state, {
            tipo_agencia: action.tipo_agencia,
            tipo_id: action.tipo_id
        })
    default:
      return state;
  }
}
