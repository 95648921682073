import React from "react";
import Loading from "components/Loading";
import { Link } from "react-router-dom";
import GoPencil from "react-icons/lib/go/pencil";
import GoPackage from "react-icons/lib/go/package";
import TiFilter from "react-icons/lib/ti/filter";
import ReactPaginate from "react-paginate";

export default props => {
  const periodos = JSON.parse(localStorage.getItem("filtros_periodos"));
  const agencias = JSON.parse(localStorage.getItem("filtros_agencias"));
  const tipos = JSON.parse(localStorage.getItem("filtros_tipos"));
  console.log(props.consolidados.data);
  return (
    <div className="element-box">
      {props.loading ? (
        <Loading />
      ) : (
        <div className="table-responsive">
          <table className="table table-lightborder table-striped">
            <thead>
              <tr>
                <th className="th-combo">
                  Agencia{" "}
                  <span className="th-triangule">
                    {" "}
                    <TiFilter />
                  </span>
                  <ul className="th-lista">
                    <li onClick={e => props.onClickAgencia("")}>Todos</li>
                    {agencias.map(e => (
                      <li
                        key={"agencias_" + e.id}
                        onClick={k => props.onClickAgencia(e.id)}
                      >
                        {e.description}
                      </li>
                    ))}
                  </ul>
                </th>
                <th className="th-combo">
                  Tipo{" "}
                  <span className="th-triangule">
                    {" "}
                    <TiFilter />
                  </span>
                  <ul className="th-lista">
                    <li onClick={e => props.onClickTipo("")}>Todos</li>
                    {tipos.map(e => (
                      <li
                        key={"tipos_" + e.id}
                        onClick={k => props.onClickTipo(e.id)}
                      >
                        {e.name}
                      </li>
                    ))}
                  </ul>
                </th>
                <th className="th-combo">
                  Perido{" "}
                  <span className="th-triangule">
                    {" "}
                    <TiFilter />
                  </span>
                  <ul className="th-lista">
                    <li onClick={e => props.onClickPeriodo("")}>Todos</li>
                    {periodos.map(e => (
                      <li
                        key={"periodos_" + e.id}
                        onClick={k => props.onClickPeriodo(e.id)}
                      >
                        {e.slug}
                      </li>
                    ))}
                  </ul>
                </th>
                <th className="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {!props.consolidados.data ? (
                <tr />
              ) : (
                props.consolidados.data.map(e => (
                  <tr key={"consolidado_" + e.id}>
                    <td>{e.agencia_name}</td>
                    <td>{e.tipo_name}</td>
                    <td>{e.periodo_slug}</td>
                    <td className="text-center">
                      <Link
                        to={"/consolidado/" + e.id}
                        className="link-default link-edit tooltip"
                      >
                        <GoPencil />
                        <span className="tooltiptext">Mostrar Resultado</span>
                      </Link>
                      {e.periodo_slug.search("jul-dic") !== -1 ? (
                        <Link
                          to={"/consolidado-anual/" + e.id}
                          className="link-default link-edit tooltip"
                        >
                          <GoPackage />
                          <span className="tooltiptext">Mostrar Consolidado Anual</span>
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}

      {!props.consolidados.total ? (
        ""
      ) : (
        <div className="move-right">
          <div />
          <ReactPaginate
            previousLabel={"Atrás"}
            nextLabel={"siguiente"}
            breakLabel={<a href="#paginado">...</a>}
            breakClassName={"break-me"}
            pageCount={props.consolidados.last_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={props.consolidados.current_page - 1}
            onPageChange={props.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </div>
  );
};
