export function login(usuario) {
  return {
    types: ["LOADING_LOGIN", "LOGIN_SUCCESS", "FAIL_LOGIN"],
    payload: {
      request: {
        method: "POST",
        url: "/get-token",
        data: {
            username: usuario.email,
            password: usuario.password
        }

      }
    }
  };
}


export function logout(usuario){
  return {
    types : ["LOADING_LOGOUT", "LOGOUT_SUCCESS", "FAIL_LOGOUT"],
    payload: {
      request: {
        method: "POST",
        url: "/lost-token",
        data: {
          user_id: JSON.parse(localStorage.getItem('user'))['id']
        }
      }
    }
  }

}

export function checkAuth(){
  return {
    types : ["LOADING_CHECK_AUTH", "CHECK_AUTH_SUCCESS", "FAIL_CHECK_AUTH"],
    payload: {
      request: {
        url: "/check-auth",
      }
    }
  }

}