import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {
    SelectSimpleField,
    renderTextarea
} from "routes/_helpers/campos";
import validate from "../helpers/validate-comentario";

import CrudContent from "routes/_hoc/CrudContent";

class FormComentario extends Component {

    render() {
        const { handleSubmit, submitting, button_message, periodos, marcas, agencias, paises } = this.props;

        return (
            <form onSubmit={handleSubmit} className="formulario-crud element-box">
                <p>Ingrese los datos del Comentario</p>
                <br />
                <Field
                    component={SelectSimpleField}
                    name="pais_id"
                    label="Pais"
                    options={paises}
                    id="pais-select"
                />
                <Field
                    component={SelectSimpleField}
                    name="agencia_id"
                    label="Agencia"
                    options={agencias}
                    id="agencia-select"
                />

                <Field
                    component={SelectSimpleField}
                    name="marca_id"
                    label="Marca"
                    options={marcas}
                    id="marca-select"
                />

                <Field
                    component={SelectSimpleField}
                    name="periodo_id"
                    label="Periodo"
                    options={periodos}
                    id="periodo-select"
                />
                <Field
                    component={renderTextarea}
                    name="comentario"
                    label="Comentario"
                    id="periodo-select"
                />


                <div className="top-line">
                    <Link to="/comentarios" className="btn btn-black">
                        Regresar
                    </Link>
                    <button
                        type="submit"
                        disabled={submitting}
                        className="btn btn-primary left-15"
                    >
                        {button_message}
                    </button>
                </div>
            </form>
        );
    }
}

export default CrudContent(reduxForm({ form: "comentario", validate })(FormComentario));
