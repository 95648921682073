import { createStore, applyMiddleware } from "redux";
import axiosMiddleware from "redux-axios-middleware";
import axios from "axios";
import { createLogger } from "redux-logger";
import reducer from "./reducers";

const loggerMiddleware = createLogger();
const client = axios.create(
  localStorage.getItem("token")
    ? {
        baseURL: "/api/v1",
        responseType: "json",
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    : {
        baseURL: "/api/v1",
        responseType: "json"
      }
);

export const store = createStore(
  reducer,
  applyMiddleware(axiosMiddleware(client), loggerMiddleware)
);
