import React, { Component } from "react";

export default class InputPaso extends Component {
  render() {
    const disabled = JSON.parse(localStorage.getItem('user')).role === 'marketing' ? '' : 'disbaled';
    return (
      <section className="pasos">
        <h3>{this.props.data.objective}</h3>
        <span className="text-mini">{this.props.data.measurement_unit}</span>
        {this.props.data.alert_message && <span className="label label-danger">{this.props.data.alert_message}</span>}
        <div className="flex-objectivo">
          <div className="flex-objectivo__first">
            <div className="paso-objectivo">
              <label htmlFor="objectivo">OBJETIVO: </label>
              <input
                type="text"
                name={"objectivo[" + this.props.data.id + "]"}
                value={this.props.objectivo}
                disabled={disabled}
                placeholder='0'
                onChange={this.props.onChangeObjectivo}
              />
            </div>
            <div className="paso-actual">
              <label htmlFor="actual">ACTUAL: </label>
              <input
                type="text"
                name={"actual[" + this.props.data.id + "]"}
                value={this.props.actual}
                disabled={disabled}
                placeholder='0'
                onChange={this.props.onChangeActual}
              />
            </div>
          </div>
          <div className="flex-objectivo__two">
            <ul className="lista-ali">
              <li>
                <span>A (>100% del Objetivo)</span>
                <span>{(parseInt(this.props.objectivo, 10) * 1.05).toFixed(1)}</span>
              </li>
              <li>
                <span>B (=100% del Objetivo)</span>
                <span>{this.props.objectivo}</span>
              </li>
              <li>
                <span>C ({"<"}100% del Objetivo)</span>
                <span>{(parseInt(this.props.objectivo, 10) * 0.95).toFixed(1)}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="pasos-result">
          <h3>RATING: </h3>
          <h1 className={"rating-" + (this.props.rating === 'N.A' ? 'na' : this.props.rating.toLowerCase())}>
            {disabled ? (
              <select
                className="select-admin"
                onChange={k => this.props.handleAdminSelect(k)}
                value={this.props.rating}
              >
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="N.A">N.A</option>
              </select>
            ) : (
              this.props.rating
            )}
          </h1>
        </div>
        <input type="hidden" name={"rating[" + this.props.data.id + "]"} value={this.props.rating} />
        <input type="hidden" name={"paso_id[" + this.props.data.id + "]"} value={this.props.data.id} />
      </section>
    );
  }
}


