import React from "react";
import Card from "components/Card";
import Loading from "components/Loading";

export default (props) => {

  return (

    <div>
      <p>Seleccione el tipo de agencia que desea evaluar</p>
      {props.loading_tipos ? (
        <Loading />
      ) : (
        <div className="flex-total">
          {props.tipos.map(e => (
            <div key={e.description} onClick={k => props.onClickEvaluar(k, e)}>
              <Card data={e} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


