import React, { Component } from "react";

export default class MetaPaso extends Component {

  render() {

    const disabled = JSON.parse(localStorage.getItem('user')).role === 'marketing' ? '' : 'disbaled';
    let disabledActual = this.props.maxima === "" || this.props.objectivo === "" || this.props.minima === ""
    if (this.props.maxima !== "" && this.props.maxima !== "" && this.props.minima !== "") {

      disabledActual = parseFloat(this.props.maxima) > parseFloat(this.props.objectivo) && parseFloat(this.props.objectivo) > parseFloat(this.props.minima) ? false : true
    }


    return (
      <section className="pasos">
        <h3>{this.props.data.objective}</h3>
        <span className="text-mini">{this.props.data.measurement_unit}</span>
        {this.props.data.alert_message && <p className="label label-danger">{this.props.data.alert_message}</p>}
        <div className="flex-objectivo aling-end">

          <div className="flex-objectivo__first flex-tico" >

            <ul className="lista-ali lista-ali-rewrite ">
              <li>
                <span>META MÁXIMA</span>
                <span>
                  <input
                    type="text"
                    name={"maxima[" + this.props.data.id + "]"}
                    value={this.props.maxima}
                    disabled={disabled}
                    placeholder='0'
                    onChange={this.props.onChangeMaxima}
                  />
                </span>
              </li>
              <li>
                <span>META ESPERADA</span>
                <span>
                  <input
                    type="text"
                    name={"objectivo[" + this.props.data.id + "]"}
                    value={this.props.objectivo}
                    disabled={disabled}
                    placeholder='0'
                    onChange={this.props.onChangeObjectivo}
                  />
                </span>
              </li>
              <li>
                <span>META MÍNIMA</span>
                <span>
                  <input
                    type="text"
                    name={"minima[" + this.props.data.id + "]"}
                    value={this.props.minima}
                    disabled={disabled}
                    placeholder='0'
                    onChange={this.props.onChangeMinima}
                  />
                </span>
              </li>

            </ul>
            <div>

              <ul className="lista-ali">
                <li className="white">
                  <span style={{ paddingRight: 20 }}>ACTUAL</span>
                  <span>
                    <input
                      type="text"
                      name={"actual[" + this.props.data.id + "]"}
                      value={this.props.actual}
                      disabled={disabled ? disabled : disabledActual}
                      placeholder='0'
                      onChange={this.props.onChangeActual}
                    />
                  </span>
                </li>
              </ul>
              {disabledActual && <span style={{ fontSize: 12, color: 'red' }}>El valor de las metas deben estar correcto</span>}
            </div>
          </div>
          <div className="flex-objectivo__two flex-tico-tow">
            <div className="pasos-result">
              <h3>RATING: </h3>
              <h1 className={"rating-" + (this.props.rating === 'N.A' ? 'na' : this.props.rating.toLowerCase())}>
                {disabled ? (
                  <select
                    className="select-admin"
                    onChange={k => this.props.handleAdminSelect(k)}
                    value={this.props.rating}
                  >
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="N.A">N.A</option>
                  </select>
                ) : (
                  this.props.rating
                )}
              </h1>
            </div>
          </div>
        </div>
        <input type="hidden" name={"rating[" + this.props.data.id + "]"} value={this.props.rating} />
        <input type="hidden" name={"paso_id[" + this.props.data.id + "]"} value={this.props.data.id} />
      </section>
    );
  }
}


