import React, { Component } from "react";
import FormCategoriaMarca from "./components/form-categoria-marca";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  saveCategoriaMarca,
  fetchCategoriaMarca,
  updateCategoriaMarca
} from "modules/categoria-marca/actions/categoria-marca-actions";

class FormCategoriaMarcaPage extends Component {
  state = {
    marca: {},
    redirect: false
  };

  componentDidMount() {
    let { _name } = this.props.match.params;
    if (_name) {
      this.props.fetchCategoriaMarca(_name);
    }
  }

  handleSubmit = marca => {
    let ambito = this;
    if (!marca.id) {
      return this.props.saveCategoriaMarca(marca).then(function(e) {
        ambito.setState({
          redirect: true
        });
      });
    } else {
      return this.props.updateCategoriaMarca(marca).then(function(e) {
        ambito.setState({
          redirect: true
        });
      });
    }
  };

  render() {
    let { _name } = this.props.match.params;
    let { categoria_marca } = this.props;

    let breadcrumb = [
      { title: "Categorias", to: "/cateogira-marcas" },
      { title: _name ? _name : "Nueva Categoría" }
    ];

    let title = _name ? "Editar Categoria " : "Nueva Categoria";
    let button_message = _name ? "Actualizar" : "Registrar";

    return this.state.redirect ? (
      <Redirect to="/categoria-marcas" />
    ) : (
      <FormCategoriaMarca
        onSubmit={this.handleSubmit}
        title={title}
        data={categoria_marca}
        links={breadcrumb}
        button_message={button_message}
      />
    );
  }
}

/* export default FormCategoriaMarcaPage; */

const mapStateToProps = state => ({
  categoria_marca: state.categoriaMarcaStore.categoria_marca
});

export default connect(mapStateToProps, {
  saveCategoriaMarca,
  fetchCategoriaMarca,
  updateCategoriaMarca
})(FormCategoriaMarcaPage);
