const initialState = {
    loading_fichas: false,
    fichas: []
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case "LOADING_FICHAS":
        return Object.assign({}, state, {
          loading_fichas: true
        });
  
      case "FICHAS_SUCCESS":
        return Object.assign({}, state, {
          fichas: action.payload.data,
          loading_fichas: false
        });
        
      default:
        return state;
    }
  };