import {
  LOADING_MARCAS,
  MARCAS_LIST_SUCCESS,
  FETCH_MARCA,
  FETCH_MARCA_SUCCESS,
  SAVE_MARCA_SUCCESS,
  UPDATE_MARCA_SUCCESS
} from "../constants";

const initialState = {
  loading_marcas: true,
  marcas: [],
  categorias: [],
  marca: {},
  agencias: [],
  paises: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_MARCAS:
      return Object.assign({}, state, {
        loading_marcas: true
      });

    case MARCAS_LIST_SUCCESS:
      return Object.assign({}, state, {
        marcas: action.payload.data,
        loading_marcas: false
      });
    case "CREATE_MARCA_SUCCESS":
      return Object.assign({}, state, {
        categorias: action.payload.data.categorias,
        agencias: action.payload.data.agencias,
        paises: action.payload.data.paises,
      });
    case FETCH_MARCA:
      return Object.assign({}, state, {
        marca: {}
      });
    case FETCH_MARCA_SUCCESS:
      return Object.assign({}, state, {
        marca: action.payload.data.marca,
        categorias: action.payload.data.categorias,
        agencias: action.payload.data.agencias,
        paises: action.payload.data.paises
      });
    case SAVE_MARCA_SUCCESS:
      return Object.assign({}, state, {
        marca: action.payload.data
      });
    case UPDATE_MARCA_SUCCESS:
      return Object.assign({}, state, {
        marca: action.payload.data
      });
    case "DELETE_MARCA_SUCCESS":
      return Object.assign({}, state, {
        marcas: state.marcas.filter(e => e.name !== action.payload.data.name)
      });
    default:
      return state;
  }
};
