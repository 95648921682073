import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Content from "routes/_hoc/Content";

import {
  fetchCategoriaMarcas,
  deleteCategoriaMarca
} from "modules/categoria-marca/actions/categoria-marca-actions";

import ListCategoriaMarca from "./components/list-categoria-marca";
import swal from "sweetalert";

class ListCategoriaMarcaPage extends Component {
  componentDidMount() {
    this.props.fetchCategoriaMarcas();
  }

  onConfirmDelete = (e, data) => {
    let ambito = this;
    e.preventDefault();
    swal({
      title: "Estás Seguro?",
      text:
        "una vez eliminado, no podras recuperar la categoria " +
        data.description +
        "!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        ambito.props.deleteCategoriaMarca(data.name).then(e => {
          if (e.type === "DELETE_CATEGORIA_MARCA_SUCCESS") {
            swal("La marca ha sido eliminada con éxito", {
              icon: "success"
            });
          }

          if (e.type === "DELETE_CATEGORIA_MARCA_FAIL") {
            if (e.error.response.status === 500) {
              swal("Ocurrió un problema interno en el servidor", {
                icon: "error"
              });
            }
          }
        });
      }
    });
  };

  render() {
    return (
      <section>
        <div className="acciones_top">
          <Link
            to="/categoria-marcas/create"
            className="btn btn-primary"
          >
          Crear Nueva Categoría
          </Link>
        </div>
        <ListCategoriaMarca
          loading={this.props.loading}
          data={this.props.categoria_marcas}
          onConfirmDelete={this.onConfirmDelete}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  categoria_marcas: state.categoriaMarcaStore.categoria_marcas,
  loading: state.categoriaMarcaStore.loading_categoria_marcas
});

export default connect(mapStateToProps, {
  fetchCategoriaMarcas,
  deleteCategoriaMarca
})(
  Content(ListCategoriaMarcaPage, {
    title: "Listado de Categorias",
    links: [{ title: "Categorias" }]
  })
);
