import React from "react";
import Loading from "components/Loading";
import EvalContent from "routes/_hoc/EvalContent";
import Select from "react-select";

function Selection(props) {
  const onChangeAgencia = e => {
    props.actions.selectedAgencia(e.currentTarget.value);
  };



  const agencia_group = !props.agencia_active  ? (
    props.selected_marca !== "Seleccione" ? (
      <div className="form-group">
        <label htmlFor="agencia">Agencia</label>
        <select
          className="form-control"
          name="agencia"
          onChange={onChangeAgencia}
          defaultValue={props.selected_agencia}
          id="agencia"
        >
          {props.agencias.map((e, i) => (
            <option key={"agencia" + e.id + i} value={e.id}>
              {e.description}
            </option>
          ))}
        </select>
      </div>
    ) : (
      ""
    )
  ) : (
    <Loading />
  );


  const onChangeMarca = e => {
    let marca_id =  e.id;
     if (marca_id !== "Seleccione") {
      props.actions
        .loadAgencias(localStorage.getItem("tipo_selected"), marca_id)
        .then(e => {
          props.actions.selectedMarca( parseInt(marca_id, 10));
          if (e.payload.data.length) {
            props.actions.selectedAgencia(e.payload.data[0].id);
            props.actions.loadPeriodos().then(e => {
              if(e.payload.data.length){
                props.actions.selectedPeriodo(e.payload.data[0].id);
              }
            }); 
          }
        });
    } 
  };
  const marca_group = !props.marca_active ? (
    <div className="form-group">
      <label htmlFor="marca">Marca</label>
      <Select
      value={props.selected_marca}
      id='marca'
      name='marca'
      options={props.marcas}
      labelKey="description"
      valueKey="id"
      clearable={false}
      onChange={onChangeMarca}
      onBlurResetsInput={false}
    />

    </div>
  ) : (
    <Loading />
  );

  const onChangePeriodo = e => {
    props.actions.selectedPeriodo(e.currentTarget.value);
  };
  const periodo_group =
    props.selected_marca !== "Seleccione" ? (
      !props.periodo_active ? (
        <div className="form-group">
          <label htmlFor="periodo">Periodo</label>
          <select
            className="form-control"
            name="periodo"
            id="periodo"
            defaultValue={props.selected_periodo}
            onChange={onChangePeriodo}
          >
            {props.periodos.map((e, i) => (
              <option key={"periodo_" + i} value={e.id}>
                {e.slug}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <Loading />
      )
    ) : (
      ""
    ); 
    const onChangePaises = e => {
      let pais_id =  e.id;
      props.actions.selectedPais(pais_id);
     if (pais_id !== "Seleccione") {
      props.actions
        .loadMarcas(localStorage.getItem("tipo_selected"), pais_id)
       
    } 
     
    }

    const paises_group = !props.paises_active ? (
      <div className="form-group">
        <label htmlFor="marca">Países</label>
        <Select
        value={props.selected_pais}
        id='pais'
        name='pais'
        options={props.paises}
        labelKey="description"
        valueKey="id"
        clearable={false}
        onChange={onChangePaises}
        onBlurResetsInput={false}
      />
  
      </div>
    ) : (
      <Loading />
    );

   
  return (
    <form className="form-evaluacion box-style">
      {paises_group}
      {marca_group} 
      {agencia_group}
      {periodo_group} 

      <div className="buttons-w group-buttons-w">
        <button
          className="btn btn-primary"
          onClick={e => props.onClickIniciar(e)}
          disabled={
            props.selected_periodo !== "Seleccione" &&
            props.selected_marca !== "Seleccione" &&
            props.selected_agencia !== "Seleccione"
              ? false
              : true
          }
        >
          Iniciar
        </button>
      </div>
    </form>
  );
}

export default EvalContent(Selection);
