import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { renderField, renderCheckbox } from "routes/_helpers/campos";
import validate from "../helpers/validate-periodo";

import CrudContent from "../../_hoc/CrudContent";

class FormPeriodo extends Component {
  componentWillReceiveProps = nextProps => {
    const { data } = nextProps;

    if (data.id !== this.props.data.id) {
      this.props.initialize(data);
    }
  };

  render() {
    const { handleSubmit, submitting, button_message } = this.props;

    return (
      <form onSubmit={handleSubmit} className="formulario-crud element-box">
        <p>Ingrese los datos de la Periodo</p>
        <br />

        <Field
          type="text"
          component={renderField}
          name="semestre"
          id="semestre"
          label="Semestre"
          placeholder="Ingresar el semestre"
        />

        <Field
        type="text"
        component={renderField}
        name="anio"
        id="anio"
        label="Año"
        placeholder="Ingresar el año"
      />

        <Field
          type="checkbox"
          component={renderCheckbox}
          name="state"
          id="state"
          label="Estado"
        />

        <div className="top-line">
          <Link to="/periodos" className="btn btn-black">
            Regresar
          </Link>
          <button
            type="submit"
            disabled={submitting}
            className="btn btn-primary left-15"
          >
            {button_message}
          </button>
        </div>
      </form>
    );
  }
}

export default CrudContent(reduxForm({ form: "periodo", validate })(FormPeriodo));
