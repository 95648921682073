import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import registerServiceWorker from './_assets/registerServiceWorker';

import { Provider } from 'react-redux'
import { store } from './modules/store';


import './_assets/index.css';   
import './_assets/responsive.css';
import 'react-select/dist/react-select.css';

import App from './App'




ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

registerServiceWorker();
