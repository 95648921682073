import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Content from "../../routes/_hoc/Content";
import { fetchAgencias, deleteAgencia } from "modules/agencia/actions/agencia-actions";

import ListAgencia from "./components/list-agencia";
import swal from "sweetalert";

class ListAgenciaPage extends Component {
  componentDidMount() {
    this.props.fetchAgencias();
  }

  onConfirmDelete = (e, data) => {
    let ambito = this;
    e.preventDefault();
    swal({
      title: "Estás Seguro?",
      text:
        "una vez eliminado, no podras recuperar la agencia " +
        data.description +
        "!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        ambito.props.deleteAgencia(data.name).then(e => {
          if (e.type === "DELETE_MARCA_SUCCESS") {
            swal("La agencia ha sido eliminada con éxito", {
              icon: "success"
            });
          }

          if (e.type === "DELETE_MARCA_FAIL") {
            if (e.error.response.status === 500) {
              swal("Ocurrió un problema interno en el servidor", {
                icon: "error"
              });
            }
          }
        });
      }
    });
  };

  render() {
    return (
      <section>
        <div className="acciones_top">
          <Link to="/agencias/create" className="btn btn-primary">
            Crear Nueva Agencia
          </Link>
        </div>
        <ListAgencia
          loading={this.props.loading_agencias}
          data={this.props.agencias}
          onConfirmDelete={this.onConfirmDelete}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  agencias: state.agenciaStore.agencias,
  loading: state.agenciaStore.loading_agencias
});

export default connect(mapStateToProps, { fetchAgencias, deleteAgencia })(
  Content(ListAgenciaPage, {
    title: "Listado de Agencias",
    links: [{ title: "Agencias" }]
  })
);
