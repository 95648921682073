import React, { Component } from "react";
import EvalContent from "routes/_hoc/EvalContent";
import RowConsolidado from "./row-consolidado";

import RowResult from "./consolidado/RowResult";
import RowSelect from "./consolidado/RowSelect";
import RowCalificacion from "./consolidado/RowCalificacion";
import { checkFloat } from "./helper.js";
class Consolidado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultados: [],
      total: 0,
      porcentaje: ""
    };
  }

  componentDidMount() {
    let ambito = this;
    this.setState({
      resultados: ambito.props.resultados,
      total: parseFloat(ambito.props.consolidado.total),
      porcentaje: ambito.props.consolidado.porcentaje
    });
  }

  handleContraFija = (e, paso, ident) => {
    if (!checkFloat(e.currentTarget.value)) {
      return;
    }
    let value = 0;
    if (e.currentTarget.value === "") {
      value = "";
    } else {
      value = parseFloat(e.currentTarget.value, 10);
    }

    let porcentaje_restar = parseFloat(
      this.state.resultados[2]["result"].filter(e => {
        return e.id === ident;
      })[0].resp
    );

    let resultados = this.state.resultados.map((k, indice) => {
      if (paso.orden === k.orden) {
        k.result = k.result.map(p => {
          return p.id === ident ? Object.assign({}, p, { resp: value }) : p;
        });
        return k;
      }

      if (indice === 4) {
        k.result = k.result.map(p => {
          return p.id === ident
            ? Object.assign({}, p, {
                resp: Math.round(value * porcentaje_restar / 100)
              })
            : p;
        });
        return k;
      }
      return k;
    });

    let obtenidos = this.state.resultados[4]["result"];
    let fijos = this.state.resultados[3]["result"];
    let suma =
      obtenidos.length === 1
        ? obtenidos[0].resp
        : obtenidos.map(m => m.resp).reduce((anterior, actual, indice) => {
            return anterior + actual;
          });
    let suma_fija =
      fijos.length === 1
        ? fijos[0].resp
        : this.state.resultados[3]["result"]
            .map(m => m.resp)
            .reduce((anterior, actual) => {
              return anterior + actual;
            });

    let porcentaje = Math.round(suma / suma_fija * 100);
    /* console.log(suma)
    console.log(suma_fija)
    console.log(porcentaje) */
    this.setState({
      resultados: resultados,
      total: Math.round(suma),
      porcentaje: porcentaje ? porcentaje : ""
    });
  };

  render() {
    const anual = this.props.anual;
    console.log(this.props)
    return (
      <form
        onSubmit={this.props.handleSubmit}
        className="steps-content box-style"
      >
        <div className="step-progress">
          <div className="consolidated">
            <h2>Consolidado {anual ? "Anual" : ""}</h2>
            <span>
              Consolidado de evaluaciones realizadas por la Agencia{" "}
              {this.props.consolidado.description + " "}
               {anual ? '' : ' en el periodo ' }
              {this.props.consolidado.anio +
                "  " +
                (anual ? '' : this.props.consolidado.semestre)}
            </span>
            <section className="content-table">
              <div className="table-responsive">
                <table className="table table-full table-striped">
                  <thead>
                    <tr>
                      <th className="top-hidden" />
                      <th className="top-hidden" />
                      {this.props.marcas.map((e, i) => (
                        <th key={"cabezera_" + i} style={{ width: "85px" }}>
                          {e.description }
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.pasos.map(e => (
                      <RowConsolidado key={"paso" + e.id} data={e} />
                    ))}
                    {this.props.resultados
                      .slice(0, 3)
                      .map((e, i) => (
                        <RowCalificacion
                          key={"calificacion_" + e.orden}
                          indice={i}
                          data={e}
                        />
                      ))}

                    {this.state.resultados.slice(3, 4).map(e => {
                      return (
                        <tr key={"resultado" + e.orden} className="table-line">
                          <td className="bottom-hidden" />
                          <td>{e.name}</td>
                          {e.result.map((k, indice) => (
                            <td
                              key={"clave_" + indice + e.orden}
                              className="text-center"
                            >
                              <input
                                type="text"
                                name={"contra_fija[" + k.id + "]"}
                                disabled={anual ? true  : false}
                                onChange={p =>
                                  this.handleContraFija(p, e, k.id)
                                }
                                className="consolidate_input"
                                value={k.resp || ""}
                              />
                            </td>
                          ))}
                        </tr>
                      );
                    })}

                    {this.state.resultados.slice(4, 5).map(e => {
                      return (
                        <tr key={"resultado" + e.orden} className="table-line">
                          <td className="bottom-hidden" />
                          <td>{e.name}</td>
                          {e.result.map((k, indice) => (
                            <td
                              key={"clave_" + indice + e.orden}
                              className="text-center"
                            >
                              <input
                                type="text"
                                name={"contra_obtenida[" + k.id + "]"}
                                onChange={t => t}
                                disabled={anual ? true : false }
                                className="consolidate_input"
                                value={k.resp || ""}
                              />
                            </td>
                          ))}
                        </tr>
                      );
                    })}

                    <tr>
                      <td className="bottom-hidden" />
                    </tr>
                    <RowResult
                      title="Total S/"
                      name="total"
                      value={this.state.total || ""}
                    />
                    <RowResult
                      title=" %"
                      name="porcentaje"
                      value={this.state.porcentaje || ""}
                    />

                    <tr>
                      <td className="bottom-hidden" />
                    </tr>

                    {this.props.resultados.slice(6).map(e => {
                      return (
                        <RowSelect data={e} key={"resultado_" + e.orden} disabled={anual ? true : false } />
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="text-right mrtop20">
                <a
                  className="btn btn-green btn-lg mright20"
                  href={
                    "/consolidado-excels?consolidate=" +
                    this.props.consolidado.id
                  }
                  id="next-button"
                >
                  Exportar a Excel
                </a>

                

                <button
                  className="btn btn-primary btn-lg"
                  onClick={this.props.onHandleUpdate}
                  id="next-button"
                >
                  Guardar Cambios
                </button>
              </div>
            </section>
          </div>
        </div>
      </form>
    );
  }
}

export default EvalContent(Consolidado);
/*
<a
    className="btn btn-green btn-lg mright20"
    href={
      "/consolidado-excels?consolidate=" +
      this.props.consolidado.id
    }
    id="next-button"
  >
    Exportar a pdf
  </a>
    */