const initialState = {
  loading: false,
  data: {},
  check_auth:  0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_LOGIN":
      return Object.assign({}, state, {
        loading: true
      });

    case "LOGIN_SUCCESS":
      return Object.assign({}, state, {
        data: action.payload.data,
        loading: false
      });
    case "FAIL_LOGIN":
      return Object.assign({}, state, {
        loading: false
      });
    case "CHECK_AUTH_SUCCESS":
      return Object.assign({}, state, {
        check_auth:  action.payload.data
      });
    default:
      return state;
  }
};
