import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Content from "routes/_hoc/Content";
  
import { fetchMarcas, deleteMarca } from "modules/marca/actions/marca-actions";
import Search from "components/Search";
import ListMarca from "./components/list-marca";
import swal from "sweetalert";

class ListMarcaPage extends Component {

  state = { 
    q: ""
  };

  componentDidMount() {
    this.props.fetchMarcas();
  }

  handleSearch = value => {
    this.props.fetchMarcas(value).then(e => {
      this.setState({
        q: value
      });
    });
  };

  onConfirmDelete = (e, data) => {
    let ambito = this;
    e.preventDefault();
    swal({
      title: "Estás Seguro?",
      text:
        "una vez eliminado, no podras recuperar la marca " +
        data.description +
        "!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        ambito.props.deleteMarca(data.name).then(e => {
          if (e.type === "DELETE_MARCA_SUCCESS") {
            swal("La marca ha sido eliminada con éxito", {
              icon: "success"
            });
          }

          if (e.type === "DELETE_MARCA_FAIL") {
            if (e.error.response.status === 500) {
              swal("Ocurrió un problema, puede que se este usando en una evaluación.", {
                icon: "error"
              });
            }
          }
        });
      }
    });
  };

  render() {
    return (
      <section>
        <div className="acciones_top espaciados">
          <Link to="/marcas/create" className="btn btn-primary">
          Crear Nueva Marca
          </Link>
          <Search handleSearch={this.handleSearch} />
        </div>
        <ListMarca
          loading={this.props.loading_marcas}
          data={this.props.marcas}
          onConfirmDelete={this.onConfirmDelete}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  marcas: state.marcaStore.marcas,
  loading: state.marcaStore.loading_marcas
});

export default connect(mapStateToProps, { fetchMarcas, deleteMarca })(
  Content(ListMarcaPage, {
    title: "Listado de Marcas",
    links: [{ title: "Marcas" }]
  })
);
