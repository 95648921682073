import React, { Component } from "react";
import EvalContent from "routes/_hoc/EvalContent";

class Resume extends Component {
  render() {
    const log = this.props.logs ? this.props.logs[0] : {};
    const marketing =
      JSON.parse(localStorage.getItem("user")).role === "marketing"
        ? true
        : false;
    const { result_letter, result_escala, periodo } = this.props.evaluacion;
    let name_autor =
      this.props.evaluador === undefined ? "" : this.props.evaluador.name;
    const cuadros = this.props.pasos.map(e => {
      var data = this.props.respuestas.filter(k => {
        return k.step_id === e.id;
      })[0];

      return (
        <tr key={"paso_" + e.id} className="table-line">
          <td>{e.objective}</td>
          <td>{e.measurement_unit}</td>
          <td
            className={
              data.rating !== "N.A"
                ? "text-center rating-color-" + data.rating.toLowerCase()
                : "text-center rating-color-na"
            }
          >
            {data.rating}
          </td>
          <td className="text-center">
            {data ? parseFloat(data.weight).toFixed(2) : 0} %
          </td>
          <td className="text-center">
            {data ? parseFloat(data.result).toFixed(2) : 0}{" "}
          </td>
        </tr>
      );
    });
    return (
      <section className="steps-content box-style">
        <div className="step-progress">
          <div className="consolidated">
            <h2>Resumen </h2>
            <span className="text-mini" />

            <section className="content-table">
              <div className="table-responsive">
                <table className="table table-full table-striped lista-ali">
                  <tbody>
                    <tr className="table-line">
                      <td>Usuario</td>
                      <td>{name_autor}</td>
                    </tr>
                    <tr className="table-line">
                      <td>Agencia</td>
                      <td>{this.props.evaluacion.agencia_name}</td>
                    </tr>
                    <tr className="table-line">
                      <td>Marca</td>
                      <td>{this.props.evaluacion.marca_name}</td>
                    </tr>
                    <tr className="table-line">
                      <td>Periodo</td>
                      <td>
                        {this.props.evaluacion.anio +
                          " - " +
                          this.props.evaluacion.semestre}
                      </td>
                    </tr>
                    <tr className="table-line">
                      <td>Pais</td>
                      <td>{this.props.evaluacion.pais}</td>
                    </tr>
                   
                  </tbody>
                </table>
              </div>
            </section>
            <section className="content-table">
              <div className="table-responsive">
                <table className="table table-full table-striped">
                  <thead>
                    <tr>
                      <th>OBJETIVOS</th>
                      <th>UNIDAD DE MEDICIÓN</th>
                      <th>RATING</th>
                      <th style={{ width: "85px" }}>PESO</th>
                      <th>RESULTADO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cuadros}

                    <tr>
                      <td className="white" />
                      <td className="white" />
                      <td className="white" />
                      <td className="text-center td-exception">100%</td>
                      <td className="text-center td-exception">
                        {this.props.evaluacion.result_general}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <section className="result-general-flex">
                <div />
                <ul className="lista-ali">
                  <li>
                    <div className="pasos-result">
                      <h3>RESULTADO GENERAL: </h3>
                    </div>
                    <div className={"pasos-result"}>
                      <h1
                        className={
                          "rating-" +
                          (result_letter ? result_letter.toLowerCase() : "na")
                        }
                      >
                        {result_letter}
                      </h1>
                    </div>
                  </li>
                  <li>
                    <span>Fee Variable Potencial %:</span>
                    <h1>20%</h1>
                  </li>
                  <li>
                    <span>Fee Variable Real %:</span>
                    <h1>{result_escala}%</h1>
                  </li>
                </ul>
              </section>
              <div className="text-right">
                {marketing ? (
                  ""
                ) : (
                  <a
                    className="btn btn-green btn-lg mright20"
                    href={
                      "/evaluacion-excels?evaluacion=" +
                      this.props.evaluacion.id
                    }
                    id="next-button"
                  >
                    Exportar a Excel
                  </a>
                )}

                <a
                  className="btn btn-gray btn-lg mright20"
                  href={
                    "/evaluacion-pdf?evaluacion=" +
                    this.props.evaluacion.id
                  }
                  id="next-button"
                >
                  Exportar a pdf
                </a>

                <span
                  className="btn btn-primary btn-lg mright20"
                  onClick={k =>
                    this.props.handleContinue(k, this.props.evaluacion)
                  }
                  id="next-button"
                >
                  Volver a Evaluar
                </span>

                {periodo !== "consolidado" ? (
                  marketing ? (
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={this.props.onHandleConsolidated}
                      id="next-button"
                    >
                      Finalizar y Enviar resultados
                    </button>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </section> 
            <div>
              { log && (<div>
                  {log.created_at + " - "}
                  <b>{log.name}</b>
                  {" - " + log.usuario}
              </div>)}
              
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default EvalContent(Resume);
/**
 * 

 */
