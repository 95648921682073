import {
  LOADING_AGENCIA,
  AGENCIAS_SUCCESS,
  LOADING_MARCA,
  MARCAS_SUCCESS,
  LOADING_PERIODO,
  PERDIODOS_SUCCESS,
  SELECTED_AGENCIA,
  SELECTED_MARCA,
  SELECTED_PERIODO,
  LOADING_PAISES,
  PAISES_SUCCESS,
  FAIL,
  SELECTED_PAIS
} from '../constants';

export function cleanForm() {
  return {
    type: "CLEAN_FORM"
  };
}
export function loadMarcas(tipo, pais_id) {
  return {
    types: [LOADING_MARCA, MARCAS_SUCCESS, FAIL],
    payload: {
      request: {
        url: "/marcas-eval?tipo=" + tipo + "&pais_id=" + pais_id,        
      }
    }
  };
}

export function loadPaises() {
  return {
    types: [LOADING_PAISES, PAISES_SUCCESS, FAIL],
    payload: {
      request: {
        url: "/paises-eval"       
      }
    }
  };
}

export function loadAgencias(tipo, marca_id) {
  return {
    types: [LOADING_AGENCIA, AGENCIAS_SUCCESS, FAIL],
    payload: {
      request: {
        url: "/agencias-eval?tipo=" + tipo + '&marca_id=' + marca_id
      }
    }
  };
}



export function loadPeriodos() {
  return {
    types: [LOADING_PERIODO, PERDIODOS_SUCCESS, FAIL],
    payload: {
      request: {
        url: "/periodos-eval"
      }
    }
  };
}


export function selectedAgencia(selected){
  return {
    type : SELECTED_AGENCIA,
    selected : selected
  }
}

export function selectedMarca(selected){
  return {
    type : SELECTED_MARCA,
    selected : selected
  }
}

export function selectedPeriodo(selected){
  return {
    type : SELECTED_PERIODO,
    selected : selected
  }
}

export function selectedPais(selected) {
  return {
    type : SELECTED_PAIS,
    selected : selected
  }
}