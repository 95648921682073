import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {
  renderField,
  SelectField,
  SelectSimpleField,
  renderCheckbox
} from "routes/_helpers/campos";
import validate from "../helpers/validate-marca";

import CrudContent from "routes/_hoc/CrudContent";

class FormMarca extends Component {
  componentWillReceiveProps = nextProps => {
    const { data } = nextProps;

    if (data.name !== this.props.data.name) {
      this.props.initialize(data);
    }
  };

  render() {
    const { handleSubmit, submitting, button_message, categorias, agencias, paises } = this.props;

    return (
      <form onSubmit={handleSubmit} className="formulario-crud element-box">
        <p>Ingrese los datos de la Marca</p>
        <br />

        <Field
          type="text"
          component={renderField}
          name="description"
          id="description"
          label="Nombre"
          placeholder="Ingresar nombre de la Marca"
        />
         <Field
          component={SelectSimpleField}
          name="pais_id"
          label="Pais"
          options={paises}
          id="pais-select"
        />

        <Field
          component={SelectSimpleField}
          name="categoria_marca_id"
          label="Categoría"
          options={categorias}
          id="state-select"
        />

        <Field
          component={SelectField}
          name="agencias"
          label="Agencias"
          options={agencias}
          id="agencias-select"
        />

        <Field
          type="checkbox"
          component={renderCheckbox}
          name="state"
          id="state"
          label="Estado"
        />

        <div className="top-line">
          <Link to="/marcas" className="btn btn-black">
            Regresar
          </Link>
          <button
            type="submit"
            disabled={submitting}
            className="btn btn-primary left-15"
          >
            {button_message}
          </button>
        </div>
      </form>
    );
  }
}

export default CrudContent(reduxForm({ form: "marca", validate })(FormMarca));
/*
           */
