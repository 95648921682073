import React from "react";
import Loading from "components/Loading";
import ReactPaginate from "react-paginate";
import TiFilter from "react-icons/lib/ti/filter";

export default props => {

    return (
        <div className="element-box">
            {props.loading ? (
                <Loading />
            ) : (
                <section>

                    <div className="table-responsive">
                        <table className="table table-lightborder table-striped">
                            <thead>
                                <tr>
                                    <th>Pais</th>
                                    <th>Agencia</th>
                                    <th>Marca</th>
                                    <th className="th-combo">
                                        Periodo
                                        <span className="th-triangule">
                                            <TiFilter />
                                        </span>
                                        <ul className="th-lista">
                                            <li onClick={e => props.onClickTipe("")}>Todos</li>
                                            {
                                                props.periodos.map(e =>
                                                    <li key={'periodo_search_' + e.id}
                                                        onClick={k => props.onClickTipe(e.id)}>{e.description}
                                                    </li>)
                                            }
                                        </ul>
                                    </th>
                                    {props.role === 'admin' && <th>Usuario</th>}
                                    <th>Comentario</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.data.map((e, ident) => (
                                    <tr key={"comentario_" + ident}>
                                        <td>{e.pais}</td>
                                        <td>{e.agencia}</td>
                                        <td>{e.marca}</td>
                                        <td>{e.periodo}</td>
                                        {props.role === 'admin' && <td>{e.email}</td>}
                                        <td>{e.comentario}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                    {!props.data.total ? (
                        ""
                    ) : (
                        <div className="move-right">
                            <div />
                            <ReactPaginate
                                previousLabel={"Atrás"}
                                nextLabel={"siguiente"}
                                breakLabel={<a href="#paginado">...</a>}
                                breakClassName={"break-me"}
                                pageCount={props.data.last_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={props.data.current_page - 1}
                                onPageChange={props.handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </section>
            )}
        </div>
    );
};
