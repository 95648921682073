import React, { Component } from "react";
import FormPeriodo from "./components/form-periodo";
import {  Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { savePeriodo , fetchPeriodo, updatePeriodo } from "modules/periodo/actions/periodo-actions";

class FormPeriodoPage extends Component {
  state = {
    periodo: {},
    redirect: false
  };

  componentDidMount() {    
    let { _slug } = this.props.match.params;
    if (_slug) {
        this.props.fetchPeriodo(_slug);
    }
  }

  handleSubmit = (periodo) => {
    let ambito = this;
    if(!periodo.id){
      return this.props.savePeriodo(periodo).then(function(e){
        ambito.setState({
          redirect: true
        });  
      });

    }else{
      return this.props.updatePeriodo(periodo).then(function(e){
        ambito.setState({
          redirect: true
        });  
      });
    }
  }


  render() {
    let { _slug } = this.props.match.params;
    let { periodo } = this.props;
    
    let breadcrumb = [
      { title: "Periodos", to: "/periodos" },
      { title: _slug ? _slug : "Nuevo Periodo" }
    ];

    let title = _slug ? "Editar Periodo" : "Nueva Periodo";
    let button_message = _slug ? 'Actualizar' : 'Registrar';

    return (
      this.state.redirect ? <Redirect  to='/periodos' />: 
      <FormPeriodo
        onSubmit={this.handleSubmit}
        title={title}
        data={periodo}
        links={breadcrumb}
        button_message={button_message}
      />
    );
  }
}

/* export default FormPeriodoPage; */

const mapStateToProps = state => ({
  periodo: state.periodoStore.periodo,
});


export default connect(mapStateToProps, { savePeriodo, fetchPeriodo, updatePeriodo })(FormPeriodoPage);
