import {
    LOADING_CATEGORIA_MARCAS,
    CATEGORIA_MARCAS_LIST_SUCCESS,
    FAIL,
} from '../constants';

export function fetchCategoriaMarcas(){
    return {
        types : [LOADING_CATEGORIA_MARCAS, CATEGORIA_MARCAS_LIST_SUCCESS, FAIL],
        payload : {
            request : {
                url : "/categoria-marca"
            }
        }
    }
}

export function saveCategoriaMarca(categoria) {
  return {
    type : 'SAVE_CATEGORIA_MARCA',
    payload :{
      request : {
        method: 'POST',
        url: '/categoria-marca',
        data: categoria
      }
    }
  }
}

export function fetchCategoriaMarca(_name) {
  return {
    type : 'FETCH_CATEGORIA_MARCA',
    payload :{
      request : {
        url: '/categoria-marca/' + _name + '/edit',
      }
    }
  }
}

export function updateCategoriaMarca(categoria) {
  return {
    type : 'UPDATE_CATEGORIA_MARCA',
    payload :{
      request : {
        method : 'PUT',
        url: '/categoria-marca/' + categoria.id,
        data: categoria
      }
    }
  }
}

export function deleteCategoriaMarca(_name) {
  return {
    type : 'DELETE_CATEGORIA_MARCA',
    payload :{
      request : {
        method : 'DELETE',
        url: '/categoria-marca/' + _name,
      }
    }
  }
}

/* 



export function deleteContact(_name) {
  return dispatch => {
    return dispatch({
      type: 'DELETE_CONTACT',
      payload: client.delete(`${url}/${_name}`)
    })
  }
} 

*/