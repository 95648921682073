import {
    LOADING_PERIODOS,
    PERIODOS_LIST_SUCCESS,
    FAIL,
} from '../constants';

export function fetchPeriodos(){
    return {
        types : [LOADING_PERIODOS, PERIODOS_LIST_SUCCESS, FAIL],
        payload : {
            request : {
                url : "/periodos"
            }
        }
    }
}

export function savePeriodo(periodo) {
  return {
    type : 'SAVE_PERIODO',
    payload :{
      request : {
        method: 'POST',
        url: '/periodos',
        data: periodo
      }
    }
  }
}

export function fetchPeriodo(_name) {
  return {
    type : 'FETCH_PERIODO',
    payload :{
      request : {
        url: '/periodos/' + _name + '/edit',
      }
    }
  }
}

export function updatePeriodo(periodo) {
  return {
    type : 'UPDATE_PERIODO',
    payload :{
      request : {
        method : 'PUT',
        url: '/periodos/' + periodo.id,
        data: periodo
      }
    }
  }
}

export function deletePeriodo(_name) {
  return {
    type : 'DELETE_PERIODO',
    payload :{
      request : {
        method : 'DELETE',
        url: '/periodos/' + _name,
      }
    }
  }
}

/* 



export function deleteContact(_id) {
  return dispatch => {
    return dispatch({
      type: 'DELETE_CONTACT',
      payload: client.delete(`${url}/${_id}`)
    })
  }
} 

*/