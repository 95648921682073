import React from "react";
import Loading from "components/Loading";
import { Link } from "react-router-dom";
import GoTrashcan from "react-icons/lib/go/trashcan";
import GoPencil from "react-icons/lib/go/pencil";

export default props => {
  return (
    <div className="element-box">
      {props.loading ? (
        <Loading />
      ) : (
        <div className="table-responsive">
          <table className="table table-lightborder table-striped">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>País</th>
                <th>Categoría</th>
                <th>Estado</th>
                <th className="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {props.data.map(e => (
                <tr key={"marca_" + e.id}>
                  <td>{e.description}</td>
                  <td>{e.pais}</td>
                  <td>{e.categoria}</td>
                  <td>
                    {e.state ? (
                      <span className="pil pil-success">activo</span>
                    ) : (
                      <span className="pil pil-danger">Inactivo</span>
                    )}
                  </td>
                  <td className="text-center">
                    <Link
                      to={"/marcas/editar/" + e.name}
                      className="link-default link-edit tooltip"
                    >
                      <GoPencil />
                      <span className="tooltiptext">Editar</span>
                    </Link>
                    <span
                      className="link-default link-delete tooltip"
                      onClick={k => props.onConfirmDelete(k, e)}
                    >
                      <GoTrashcan />
                      <span className="tooltiptext">Eliminar</span>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
