import React, { Component } from "react";

export default class ListPaso extends Component {
  render() {
    const role = JSON.parse(localStorage.getItem("user")).role;
    const disabled = role === "marketing" ? "" : "disbaled";
    let { inputs } = this.props;
    console.log(inputs)
    return (
      <section className="pasos">
        <h3>{this.props.data.objective}</h3>
        <span className="text-mini">{this.props.data.measurement_unit}</span>
        {this.props.data.alert_message && <p className="label label-danger">{this.props.data.alert_message}</p>}
        <ul className="lista-ali">
          {this.props.data.questions.map((e, i) => (
            <li key={"cuantitativo_" + i}>
              <div>
                <p className='completar-text'>{e.name} </p>
                {e.type === 1 ? (
                  <span className="label label-danger">
                    EVALUADO SOLO POR EL EQUIPO DIGITAL
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div>

                <select
                  name={"fila_" + e.id + "[" + this.props.data.id + "]"}
                  value={inputs["fila_" + e.id]}
                  onChange={ambito => this.props.onChangeList(ambito, e)}
                  disabled={disabled}
                >
                  <option value="N.A">N.A</option>
                  <option value="C">C</option>
                  <option value="B">B</option>
                  <option value="A">A</option>
                </select>

              </div>
            </li>
          ))}
        </ul>
        <div className="pasos-result">
          <h3>RATING: </h3>
          <h1
            className={
              "rating-" +
              (this.props.rating === "N.A"
                ? "na"
                : this.props.rating.toLowerCase())
            }
          >
            {disabled ? (
              <select
                className="select-admin"
                onChange={k => this.props.handleAdminSelect(k)}
                value={this.props.rating}
              >
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="N.A">N.A</option>
              </select>
            ) : (
              this.props.rating
            )}
          </h1>
        </div>
        <input
          type="hidden"
          name={"rating[" + this.props.data.id + "]"}
          value={this.props.rating}
        />
        <input
          type="hidden"
          name={"paso_id[" + this.props.data.id + "]"}
          value={this.props.data.id}
        />
      </section>
    );
  }
}