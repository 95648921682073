import React, { Component } from "react";
import { connect } from "react-redux";
import Content from "routes/_hoc/Content";

import ListLogs from "./components/list-logs";
import { fetchLogs } from "modules/logs/logs-actions";

class LogsPage extends Component {
    state = {
        page: 1,
        tipo: '',
    };

    componentDidMount() {
        this.props.fetchLogs();
    }
    onClickTipe = tipo => {
        this.props
            .fetchLogs(this.state.page, tipo)
            .then(e => {
                this.setState({
                    tipo: tipo,
                    page: this.state.page
                });
            });
    }

    handlePageClick = page => {
        this.props
            .fetchLogs(page.selected + 1, this.state.tipo)
            .then(e => {
                this.setState({
                    tipo: this.state.tipo,
                    page: page.selected + 1
                });
            });
    };
    render() {
        return (
            <section>
                <div className="acciones_top espaciados">
                    <div>

                        <a href={"/logs-excels?tipo=" + this.state.tipo} className="btn btn-green ml-1">Exportar logs</a>


                    </div>
                </div>
                <div>
                    <ListLogs
                        loading={this.props.loading}
                        logs={this.props.logs}
                        onClickTipe={this.onClickTipe}
                        handlePageClick={this.handlePageClick}
                    />

                </div>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    logs: state.logStore.logs,
    loading: state.logStore.loading_logs
});

export default connect(mapStateToProps, { fetchLogs })(
    Content(LogsPage, {
        title: "Listado de logs",
        links: [{ title: "Logs" }]
    })
);
