const initialState = {
  pasos: [],
  loading_consolidados: false,
  loading_save_consolidado: false,
  consolidate: {},
  consolidados: [],
  marcas : [],
  resultados: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_CONSOLIDADO":
      return Object.assign({}, state, {
        loading_pasos: true,
        consolidate: {}
      });
    case "CONSOLIDADO_SUCCESS":
      return Object.assign({}, state, {
        pasos: action.payload.data.pasos,
        marcas: action.payload.data.marcas,
        resultados: action.payload.data.resultados,
        consolidate: action.payload.data.consolidate,
        loading_pasos: false,
      });
    case "LOADING_CONSOLIDADOS":
      return Object.assign({}, state, {
        loading_consolidados: true
      });

    case "CONSOLIDADOS_SUCCESS":
      return Object.assign({}, state, {
        consolidados: action.payload.data,
        loading_consolidados: false
      });

    case 'LOADING_SAVE_CONSOLIDADO':
      return Object.assign({}, state, {
        loading_save_consolidado: true,
        consolidate: {}
      });
    case 'SAVE_CONSOLIDADO_SUCCESS':
      return Object.assign({}, state, {
        loading_save_consolidado: false,
        consolidate: action.payload.data
      });

    default:
      return state;
  }
};
