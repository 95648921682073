import React from 'react'
import IcoMedios from "../_assets/ico-medios.svg";
import IcoDigital from "../_assets/ico-digital.svg";
import IcoCreativa from "../_assets/ico-creativas.svg";
import IcoEcommerce from "../_assets/ico-ecommerce.svg";
import IcoMediosOnline from "../_assets/ico-medios-online.svg";
import IcoPromociones from "../_assets/icon-promotion.svg";

export default function Card(props) {
  let image = '';
  switch (props.data.description) {
    case 'agencias-de-medios':
      image = IcoMedios
      break
    case 'agencia-medios-offline':
      image = IcoMedios
      break
    case 'agencia-medios-online':
      image = IcoMediosOnline
      break
    case 'agencias-digital':
      image = IcoDigital
      break
    case 'agencia-digital-publicidad':
      image = IcoDigital
      break
    case 'agencia-digital-ecommerce':
      image = IcoEcommerce
      break
    case 'agencias-creativa':
      image = IcoCreativa
      break
    case 'agencia-creativa-2':
      image = IcoCreativa
      break
    case 'agencia-promociones':
      image = IcoPromociones
      break
    default:
      image = ''
  }
  return (
    <div className="element-box el-tablo">
      <img src={image} alt="" />
      <h4 className="value">{props.data.name}</h4>
      {props.data.alert_message && <span className="label label-danger">{props.data.alert_message}</span>}
    </div>
  )
}
