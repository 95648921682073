import {
  LOADING_PASOS,
  PASOS_SUCCESS,
  CHANGE_POSITION,
  CHANGE_ANWSER_OBJECTIVE,
  CHANGE_ANWSER_ACTUAL,
  FAIL
} from "../constants";

export function loadPasos(data) {
  return {
    types: [LOADING_PASOS, PASOS_SUCCESS, FAIL],
    payload: {
      request: {
        url:
          "/pasos/" +
          data.tipo_selected +
          "?marca_id=" +
          data.marca_id +
          "&agencia_id=" +
          data.agencia_id +
          "&periodo_id=" +
          data.periodo_id +
          "&pais_id=" +
          data.pais_id
      }
    }
  };
}

export function changePosition($position) {
  return {
    type: CHANGE_POSITION,
    position: $position
  };
}

export function changeAnswerObjective($data, $pregunta_id) {
  return {
    type: CHANGE_ANWSER_OBJECTIVE,
    data: $data,
    pregunta_id: $pregunta_id
  };
}

export function changeAnswerActual($data, $pregunta_id) {
  return {
    type: CHANGE_ANWSER_ACTUAL,
    data: $data,
    pregunta_id: $pregunta_id
  };
}
