import {
    LOADING_PERIODOS,
    PERIODOS_LIST_SUCCESS,
    FETCH_PERIODO,
    FETCH_PERIODO_SUCCESS,
    SAVE_PERIODO_SUCCESS,
    UPDATE_PERIODO_SUCCESS,
} from '../constants';

const initialState = {
    loading_periodos : true,
    periodos : [],
    periodo : {}
}


export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_PERIODOS:
            return Object.assign({}, state,{
                loading_periodos: true
            });

        case PERIODOS_LIST_SUCCESS:
            return Object.assign({}, state,{
                periodos : action.payload.data,
                loading_periodos: false
            })
        case FETCH_PERIODO:
            return Object.assign({}, state, {
                periodo: {}
            })
        case FETCH_PERIODO_SUCCESS:
            return Object.assign({}, state, {
                periodo: action.payload.data
            })
        case SAVE_PERIODO_SUCCESS: 
            return Object.assign({}, state, {
                periodo: action.payload.data
            })
        case UPDATE_PERIODO_SUCCESS:
            return Object.assign({}, state, {
                periodo: action.payload.data
            }) 
        case 'DELETE_PERIODO_SUCCESS':
            return Object.assign({}, state, {
                periodos : state.periodos.filter( e => e.id !== action.payload.data.id)
            })
        default:
            return state;
    }
}