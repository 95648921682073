export default values => {
    const errors = {};
    if (!values.pais_id) {
        errors.pais_id = "Este campo es obligatorio";
    }
    if (!values.agencia_id) {
        errors.agencia_id = "Este campo es obligatorio";
    }
    if (!values.marca_id) {
        errors.marca_id = "Este campo es obligatorio";
    }
    if (!values.periodo_id) {
        errors.periodo_id = "Este campo es obligatorio";
    }
    if (!values.comentario) {
        errors.comentario = "Este campo es obligatorio";
    }
    return errors;
};
