
import React from 'react'

export default () => {
  return (
    <section className="center-relative">
    <div className="center-absolute">
        <h2>404</h2>
        <span>Lo sentimos, pagina no encontrada.</span>
    </div>
  </section>
  )
}
