import React from 'react'

const Loading = (props) => (
    <section className="loading-block">
        <div className="loading-icon">
            <div className="icon"></div>
        </div>
    </section>
    );

export default Loading;

