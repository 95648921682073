
const initialState = {
    loading: false,
    periodos: [],
    tipos: [],
    agencias : []
  };
  

export default (state = initialState, action) => {
    switch (action.type) {
      case "LOADING_FILTROS":
        return Object.assign({}, state, {
          loading: true
        });
  
      case "FILTROS_SUCCESS":
        return Object.assign({}, state, {
          periodos : action.payload.data.periodos,
          tipos : action.payload.data.tipos,
          agencias : action.payload.data.agencias,
          loading_fichas: false
        });
        
      default:
        return state;
    }
  };