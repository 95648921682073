import React, { Component } from "react";
import FormComentario from "./components/form-comentario";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createComentario, saveComentario } from "modules/comentarios/comentarios-actions";

class FormComentarioPage extends Component {
    state = {
        paises: [],
        agencias: [],
        marcas: [],
        periodos: [],
        loadingComentario: true,
        redirect: false
    };

    componentDidMount() {
        this.props.createComentario();
    }

    handleSubmit = (comentario) => {
        let ambito = this;
        return this.props.saveComentario({
            ...comentario,
            agencia_id: comentario.agencia_id.id,
            pais_id: comentario.pais_id.id,
            periodo_id: comentario.periodo_id.id,
            marca_id: comentario.marca_id.id
        }).then(function (e) {
            ambito.setState({
                redirect: true
            });
        });
    }


    render() {

        let { paises, agencias, marcas, periodos } = this.props;
        let breadcrumb = [
            { title: "Comentarios", to: "/comentarios" },
            { title: "Nuevo Comentario" }
        ];

        let button_message = "Registrar";

        return (
            this.state.redirect ? <Redirect to='/comentarios' /> :
                <FormComentario
                    onSubmit={this.handleSubmit}
                    title={'Nuevo Comentario'}
                    paises={paises}
                    agencias={agencias}
                    marcas={marcas}
                    periodos={periodos}
                    links={breadcrumb}
                    button_message={button_message}
                />
        );
    }
}

const mapStateToProps = state => ({
    paises: state.comentariosStore.paises,
    agencias: state.comentariosStore.agencias,
    marcas: state.comentariosStore.marcas,
    periodos: state.comentariosStore.periodos,
    loadingComentario: state.comentariosStore.loadingComentario
});


export default connect(mapStateToProps, { createComentario, saveComentario })(FormComentarioPage);
