import React, { Component } from "react";
import FormMarca from "./components/form-marca";
import {  Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { saveMarca , fetchMarca, updateMarca,createMarca } from "modules/marca/actions/marca-actions";

class FormMarcaPage extends Component {
  state = {
    marca: {},
    redirect: false
  };

  componentDidMount() {    
    let { _name } = this.props.match.params;
    if (_name) {
        this.props.fetchMarca(_name);
    }else{
      this.props.createMarca();
    }
  }

  handleSubmit = (marca) => {
    let ambito = this; 
    let datos = { ...marca , pais_id : typeof marca.pais_id === "number" ? marca.pais_id : marca.pais_id.id  }
    if(!marca.id){
      return this.props.saveMarca(datos).then(function(e){
        ambito.setState({
          redirect: true
        });  
      });

    }else{
      return this.props.updateMarca(datos).then(function(e){
        ambito.setState({
          redirect: true
        });  
      });
    }
  }


  render() {
    let { _name } = this.props.match.params;
    let { marca, categorias , agencias, paises} = this.props;

    let breadcrumb = [
      { title: "Marcas", to: "/marcas" },
      { title: _name ? _name : "Nueva Marca" }
    ];

    let title = _name ? "Editar Marca" : "Nueva Marca";
    let button_message = _name ? 'Actualizar' : 'Registrar';

    return (
      this.state.redirect ? <Redirect  to='/marcas' />: 
      <FormMarca
        onSubmit={this.handleSubmit}
        title={title}
        data={marca}
        agencias={agencias}
        paises={paises}
        categorias={categorias}
        links={breadcrumb}
        button_message={button_message}
      />
    );
  }
}

/* export default FormMarcaPage; */

const mapStateToProps = state => ({
  marca: state.marcaStore.marca,
  categorias: state.marcaStore.categorias,
  agencias: state.marcaStore.agencias,
  paises: state.marcaStore.paises,
});


export default connect(mapStateToProps, { saveMarca, fetchMarca, updateMarca,createMarca })(FormMarcaPage);
