import {
    LOADING_PAISES,
    PAISES_LIST_SUCCESS,
    FAIL,
} from '../constants';

export function fetchPaises(){
    return {
        types : [LOADING_PAISES, PAISES_LIST_SUCCESS, FAIL],
        payload : {
            request : {
                url : "/paises"
            }
        }
    }
}

export function createPais(){
  return {
    type : 'CREATE_PAIS',
    payload :{
      request : {
        url: '/paises/create',
      }
    }
  }
}

export function savePais(pais) {
  return {
    type : 'SAVE_PAIS',
    payload :{
      request : {
        method: 'POST',
        url: '/paises',
        data: pais
      }
    }
  }
}

export function fetchPais(_name) {
  return {
    type : 'FETCH_PAIS',
    payload :{
      request : {
        url: '/paises/' + _name + '/edit',
      }
    }
  }
}

export function updatePais(pais) {
  return {
    type : 'UPDATE_PAIS',
    payload :{
      request : {
        method : 'PUT',
        url: '/paises/' + pais.id,
        data: pais
      }
    }
  }
}

export function deletePais(_id) {
  return {
    type : 'DELETE_PAIS',
    payload :{
      request : {
        method : 'DELETE',
        url: '/paises/' + _id,
      }
    }
  }
}

/* 



export function deleteContact(_id) {
  return dispatch => {
    return dispatch({
      type: 'DELETE_CONTACT',
      payload: client.delete(`${url}/${_id}`)
    })
  }
} 

*/