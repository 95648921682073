import React from "react";
import Loading from "components/Loading";
import ReactPaginate from "react-paginate";
import TiFilter from "react-icons/lib/ti/filter";

export default props => {

    return (
        <div className="element-box">
            {props.loading ? (
                <Loading />
            ) : (
                <div className="table-responsive">
                    <table className="table table-lightborder table-striped">
                        <thead>
                            <tr>
                                <th>Usuario</th>
                                <th>Acción</th>
                                <th className="th-combo">
                                    Modulo
                                    <span className="th-triangule">
                                        <TiFilter />
                                    </span>
                                    <ul className="th-lista">
                                        <li onClick={e => props.onClickTipe("")}>Todos</li>
                                        <li onClick={e => props.onClickTipe("Usuario")}>Usuarios</li>
                                        <li onClick={e => props.onClickTipe("Evaluación")}>Evaluación</li>
                                    </ul>
                                </th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!props.logs.data ? (
                                <tr />
                            ) : (
                                props.logs.data.map(e => (
                                    <tr key={"logs_" + e.id}>
                                        <td>{e.email}</td>
                                        <td>{e.name}</td>
                                        <td>{e.action}</td>
                                        <td>{e.creation}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            )}

            {!props.logs.total ? (
                ""
            ) : (
                <div className="move-right">
                    <div />
                    <ReactPaginate
                        previousLabel={"Atrás"}
                        nextLabel={"siguiente"}
                        breakLabel={<a href="#paginado">...</a>}
                        breakClassName={"break-me"}
                        pageCount={props.logs.last_page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        forcePage={props.logs.current_page - 1}
                        onPageChange={props.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </div>
            )}
        </div>
    );
};
