export function saveEval(asnwer, current_step){ 
    return {
        types: ['LOADING_OUT', 'OUT_SUCCESS', 'FAIL'],
        payload: {
          request: {
            method: 'POST',
            url: JSON.parse(localStorage.getItem('user')).role ==='admin' ?  "/save-eval-admin" : "/save-eval",
            data: {
                agencia_id : localStorage.getItem('selected_agencia'),
                marca_id : localStorage.getItem('selected_marca'),
                periodo_id : localStorage.getItem('selected_periodo'),
                pais_id : localStorage.getItem('selected_pais'),
                tipo_selected : localStorage.getItem('tipo_selected'),
                asnwer: asnwer,
                current_step: current_step
            }
          }
        }
    };
}

