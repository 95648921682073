import React, { Component } from "react";
import FormLogin from "./form-login";
import { connect } from "react-redux";
import { login, checkAuth } from "modules/login/login-actions";
import { filtros } from "modules/filters/filter-actions";
import { SubmissionError } from "redux-form";
import { Redirect } from "react-router-dom";
import logo from "../../_assets/logo.png";

class LoginPage extends Component {
  componentDidMount(){
    this.props.checkAuth()
  }
  handleSubmit = values => {
    return this.props
      .login(values)
      .then(e => {
        if (e.payload.data.error === "invalid_credentials") {
          localStorage.clear();
          throw new SubmissionError({
            _error: "credenciales son invalidas!"
          });
        } else if (e.payload.status === 200) {
          localStorage.setItem("token", "Bearer " + e.payload.data.token);
          localStorage.setItem("role", e.payload.data.role);
          localStorage.setItem("user",  JSON.stringify(e.payload.data.user));
          this.props.filtros("Bearer " + e.payload.data.token).then(k => {
            localStorage.setItem('filtros_periodos', JSON.stringify(this.props.filtros_periodos));
            localStorage.setItem('filtros_agencias', JSON.stringify(this.props.filtros_agencias));
            localStorage.setItem('filtros_tipos', JSON.stringify(this.props.filtros_tipos));
            if (e.payload.data.role === "marketing") {
              window.location.replace("/");
            } else {
              window.location.replace("/consolidados");
            } 
          });
        }
      })
      .catch(e => {
        localStorage.clear();
        throw new SubmissionError({
          _error: "credenciales son invalidas!"
        });
      });
  };
  render() {
    return this.props.check ? <Redirect to='/' /> : (
      <div className="auth-box-w">
        <span className="auth-version">version 2.0</span>
        <div className="logo-w">
          <div className="logo" href="index.html">
            <div className="logo-element">
              <img
                alt="logo"
                src={logo}
                style={{ width: "50px" }}
              />
            </div>
            <div className="logo-label">
              <h4>
              Evaluación de Agencias <br /> de Publicidad Alicorp
              </h4>
            </div>
          </div>
        </div>

        <FormLogin onSubmit={this.handleSubmit} loading={this.props.loading} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loginStore.loading,
  data: state.loginStore.data,
  filtros_periodos : state.filterStore.periodos,
  filtros_tipos : state.filterStore.tipos,
  filtros_agencias : state.filterStore.agencias,
  check: state.loginStore.check_auth
});

export default connect(mapStateToProps, { login, filtros, checkAuth })(LoginPage);
