import React, { Component } from 'react'
import Title from 'components/Title';
import Breadcrumb from 'components/Breadcrumb';

export default function Content(WrappedConent, complements = {
    title : 'esperando un titulo',
    links : []
}){

    return class ContentHoc extends Component {
        toggleMenu = e => {  
            let aside = document.getElementById('menuAside');     
            let background = document.getElementById('masterBlackMenu');
            background.style.display = "block";     
            aside.style.display = "block";                        
        }
        
        
        render() {
            let token = localStorage.getItem('token')
            return <div>
                { token ? <Breadcrumb links={complements.links} /> : ''}
                { token ?<div className="menu-hidden" 
                    id="toggleMenu"
                    onClick={this.toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>: ''}
                <section className="content-box">
                    { token ?<Title title={complements.title} /> : ''}
                    <WrappedConent {...this.props} />
                </section>
            </div>;
        }
    }
    
}
