import React, { Component } from "react";
import ListPasos from "./components/list-pasos";
import { connect } from "react-redux";
import Loading from "components/Loading";
import swal from "sweetalert";

import {
  loadPasos,
  changePosition
} from "modules/evaluacion/actions/analisis-actions";

import { saveEval } from "modules/evaluacion/actions/pasos-actions";

class PasosPage extends Component {
  componentDidMount() {
    this.props
      .loadPasos({
        tipo_selected: localStorage.getItem("tipo_selected"),
        marca_id: localStorage.getItem("selected_marca"),
        agencia_id: localStorage.getItem("selected_agencia"),
        periodo_id: localStorage.getItem("selected_periodo"),
        pais_id: localStorage.getItem("selected_pais")
      })
      .then(e => {
        if (!e.payload.data.evaluacion) {
          this.props.changePosition(1);
        }
      });
  }

  onHandleResume = e => {
    e.preventDefault();
    this.props
      .saveEval(this.getValuesForm(), this.props.current_step )
      .then(e => {
        localStorage.setItem("evaluacion_id", this.props.evaluacion_id);
        this.props.history.push({
          pathname: "/resume"
        });
      });
  };

  /*
  * Guarda un avanze de llenar el cuestionario
  */

  getValuesForm() {
    var elements = document.getElementById("formulario").elements;
    var obj = {};
    for (var i = 0; i < elements.length; i++) {
      var item = elements.item(i);
      obj[item.name] = item.value;
    }
    return obj;
  }

  onHandleSaveOut = (e, salir) => {
    e.preventDefault();
    this.props
      .saveEval(this.getValuesForm(), this.props.current_step)
      .then(e => {
        localStorage.setItem("evaluacion_id", this.props.evaluacion_id);
        if (salir) {
          swal("Estas seguro?", {
            buttons: ["No", "Si"]
          }).then(value => {
            if (value) {
              this.props.history.push({
                pathname: "/historial"
              });
            }
          });
        }else{
          swal("Evaluación guardada con éxito", {
            icon: "success"
          });
        }

      });
  };

  render() {
    const {
      pasos,
      changePosition,
      current_step,
      evaluacion,
      loading_pasos
    } = this.props;

    const breadcrumb = [
      {
        title: "Evaluar " + localStorage.getItem("tipo_selected"),
        to: "/evaluacion"
      },
      { title: "listado de pasos" }
    ];
    const title = "Evaluar " + localStorage.getItem("tipo_selected");
    return loading_pasos ? (
      <Loading />
    ) : (
      <ListPasos
        links={breadcrumb}
        title={title}
        pasos={pasos}
        current_step={current_step}
        evaluacion={evaluacion}
        onHandleResume={this.onHandleResume}
        changePosition={changePosition}
        onHandleSaveOut={this.onHandleSaveOut}
      />
    );
  }
}

const mapStateToProps = state => ({
  pasos: state.analisisStore.pasos,
  loading_pasos: state.analisisStore.loading_pasos,
  current_step: state.analisisStore.current_step,
  evaluacion: state.analisisStore.evaluacion,
  evaluacion_id: state.pasoStore.evaluacion_id
});

export default connect(mapStateToProps, {
  loadPasos,
  changePosition,
  saveEval
})(PasosPage);
