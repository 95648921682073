// The Modal component is a normal React component, so we can
// render it wherever we like without needing to know that it's
// implemented with portals.
import React from "react";
import Modal from "routes/_portals/Modal";
import * as TodoActions from "modules/_portal/modal-actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
class ModalError extends React.Component {
  state = {
    auth: true
  };

  onHandleClose = (e, data) => {
    this.props.actions.closeModal();
    if(data === 401 ){
        this.setState({
            auth : false
        })
    }
  };
  render() {
    const { error, data } = this.props.modal;
    
    if(!this.state.auth){
        return  <Redirect to="/login"/>
    }
    if(!error){
        return "";
    }
    switch (data.status) {
      case 401:
        localStorage.setItem("token", "");
        return (
          <Redirect to="/login" />
        );
      case 500:
        return (
          <Modal>
            <div className="modal-cover">
              <div className="modal">
                <div className="modal-text">
                  Ha ocurrido un problema con la red. Comuníquese con el area de
                  sistemas para solucionar lo antes posible este problema.
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    id="next-button"
                    onClick={e => this.onHandleClose(e, data.status)}
                  >
                    Cerrar Aviso
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return null;
    }
  }
}

const mapStateToProps = state => ({
  modal: state.modal
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(TodoActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalError);
