export function filtros(token) {
    console.log(token)
  return {
    types: ["LOADING_FILTROS", "FILTROS_SUCCESS", "FAIL"],
    payload: {
      request: {
        url: "/filtros",
        headers: {
            authorization: token
        }
      },
    }
  };
}
