const initialState = {
  answers: [],
  evaluacion_id: "",
  pasos:[],
  loading_pasos: false,
  evaluacion : "",
  save_consolidado:  false,
  loading_evaluacion: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_OUT':
      return Object.assign({}, state, {
        loading_pasos: true
      });
    case 'OUT_SUCCESS':
      return Object.assign({}, state, {
        answers: action.payload.data.answers,
        evaluacion_id: action.payload.data.evaluacion_id,
        evaluacion: action.payload.data.evaluacion,
        pasos: action.payload.data.pasos,
        loading_pasos: false
      });
    case 'LOADING_EVAL': 
      return Object.assign({}, state, {
        loading_evaluacion: true
      });
    case 'EVAL_SUCCESS':
      return Object.assign({}, state, {
        answers: action.payload.data.answers,
        evaluacion_id: action.payload.data.evaluacion_id,
        evaluacion: action.payload.data.evaluacion,
        evaluador: action.payload.data.evaluador,
        logs: action.payload.data.logs,
        pasos: action.payload.data.pasos,
        loading_evaluacion: false,
        loading_pasos: false
      });
    case 'LOADING_CONSOLIDATE':
    return Object.assign({}, state, {
      save_consolidado: false
    });
    case 'CONSOLIDATE_SUCCESS':
      return Object.assign({}, state, {
        save_consolidado: true
      });
    default:
      return state;
  }
};
