import { FAIL, CLOSE_MODAL } from './constants'

const initialState = {
  error: false,
  data: ''
};

export default function evaluacion(state = initialState, action) {
  switch (action.type) {
    case FAIL:
      return Object.assign({}, state, {
        error: true,
        data : action.error.response ? action.error.response : ''  
      });
    case CLOSE_MODAL:
      return Object.assign({}, state, {
        error: false
      });
    default:
      return state;
  }
}
