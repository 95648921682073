import { TIPO_SELECTED,
    LOADING_TIPOS,
    TIPOS_SUCCESS,
    FAIL } from '../constants'


export function tipoSelected(tipo,id){
    return {
      type : TIPO_SELECTED,
      tipo_agencia : tipo,
      tipo_id: id
    }
}

export function loadTipos(){
    return {
        types: [LOADING_TIPOS, TIPOS_SUCCESS, FAIL],
        payload: {
          request: {
            url: "/tipos"
          }
        }
    };
}