const ratingByAll = (maxima_asig, objectivo_asig, minima_asig, actual_asig) => {
  let maxima = parseFloat(maxima_asig, 10)
  let objectivo = parseFloat(objectivo_asig, 10)
  let minima = parseFloat(minima_asig, 10)
  let actual = parseFloat(actual_asig, 10)
  console.log("ratingByAll")
  if (maxima <= actual) {
    return "A";
  }
  if (maxima > objectivo) {
    if (actual < maxima && actual > objectivo) {
      let tanteo_max = maxima - actual;
      let tanteo_obj = actual - objectivo;
      return tanteo_obj >= tanteo_max ? "A" : "B";
    }

    if (actual <= objectivo && actual > minima) {
      let tanteo_objectivo = objectivo - actual;
      let tanteo_min = actual - minima;
      return tanteo_objectivo > tanteo_min ? "C" : "B";
    }
    if (minima >= actual) {
      return "C";
    }
    return "N.A";
  } else {
    return "N.A";

  }
}

const ratingByObjective = (actual, objetive, tipo_agencia = "") => {
  let objectivo = parseInt(objetive, 10);
  let number = objectivo * 0.05
  return actual
    ? actual >= objectivo + number
      ? "A"
      : objectivo + number > actual && objectivo - number < actual
        ? "B"
        : "C"
    : "N.A";
};

const ratingByObjectiveShare = (actual, objetive, tipo_agencia = "") => {
  console.log(tipo_agencia);
  let objectivo = parseInt(objetive, 10);
  let number = tipo_agencia === 'Agencias Digital' ? 3 : objectivo * 0.03;
  return actual
    ? actual >= objectivo + number
      ? "A"
      : objectivo + number > actual && objectivo - number < actual
        ? "B"
        : "C"
    : "N.A";
};
const ratingByEbes = (actual, objetive) => {
  console.log("ratingByEbes")

  let objectivo = parseInt(objetive, 10);
  return actual
    ? actual >= objectivo + 5
      ? "A"
      : objectivo + 5 > actual && objectivo - 5 < actual
        ? "B"
        : "C"
    : "N.A";
};

const checkFloat = value => {
  const re = /^[0-9]\d*(\.)?[0-9]?$/;
  return (value === "") | re.test(value);
};

const checkFloatPercentage = value => {
  if (value > 100) {
    return false;
  }
  const re = /^[1-9]\d*(\.)?[1-9]?$/;
  return (value === "") | re.test(value);
};

const ratingByKpis = (value, data, estado_array) => {



  let rating = 0;
  let count_reales = 0;
  let new_object;
  let new_result = estado_array.map(k => {
    new_object =
      k.name === data.name ? Object.assign({}, k, { asnwser: value }) : k;

    rating +=
      new_object.asnwser === "A"
        ? 20
        : new_object.asnwser === "B"
          ? 15
          : new_object.asnwser === "C"
            ? 10
            : 0;

    count_reales =
      new_object.asnwser === "A" ||
        new_object.asnwser === "B" ||
        new_object.asnwser === "C"
        ? count_reales + 1
        : count_reales;
    return new_object;
  });

  let formula = rating / count_reales;
  let resultado =
    formula <= 20 && formula >= 17.5
      ? "A"
      : formula < 17.5 && formula >= 13
        ? "B"
        : formula < 13 && formula > 1
          ? "C"
          : "N.A";

  return {
    resultado: resultado,
    new_result: new_result
  };
};

const ratingByKpisInputs = inputs => {
  console.log("ratingByKpisInputs")
  let rating = 0;
  let count_reales = 0;
  for (let i in inputs) {
    if (!i.search("rating_")) {
      rating +=
        inputs[i] === "A"
          ? 20
          : inputs[i] === "B"
            ? 15
            : inputs[i] === "C"
              ? 10
              : 0;

      count_reales =
        inputs[i] === "A" ||
          inputs[i] === "B" ||
          inputs[i] === "C"
          ? count_reales + 1
          : count_reales;
    }
  }
  let formula = rating / count_reales;
  let resultado =
    formula <= 20 && formula > 17.4
      ? "A"
      : formula <= 17.4 && formula > 12.4
        ? "B"
        : formula <= 12.4 && formula > 0
          ? "C"
          : "N.A";

  return resultado
};

module.exports = {
  ratingByObjective: ratingByObjective,
  checkFloat: checkFloat,
  ratingByObjectiveShare: ratingByObjectiveShare,
  checkFloatPercentage: checkFloatPercentage,
  ratingByKpis: ratingByKpis,
  ratingByEbes: ratingByEbes,
  ratingByKpisInputs: ratingByKpisInputs,
  ratingByAll: ratingByAll,
};
