import React from "react";
import Loading from "components/Loading";
/* import { Link } from "react-router-dom"; */
import GoPencil from "react-icons/lib/go/pencil";
import GoEye from "react-icons/lib/go/eye";
import ReactPaginate from "react-paginate";
import TiFilter from "react-icons/lib/ti/filter";

export default props => {
  const periodos = JSON.parse(localStorage.getItem("filtros_periodos"));
  const agencias = JSON.parse(localStorage.getItem("filtros_agencias"));
  const tipos = JSON.parse(localStorage.getItem("filtros_tipos"));
  return (
    <div className="element-box">
      {props.loading ? (
        <Loading />
      ) : (
        <div className="table-responsive">
          <table className="table table-lightborder table-striped">
            <thead>
              <tr>
                <th className="th-combo">
                  Agencia{" "}
                  <span className="th-triangule">
                    {" "}
                    <TiFilter />
                  </span>
                  <ul className="th-lista">
                    <li onClick={e => props.onClickAgencia("")}>Todos</li>
                    {agencias.map(e => (
                      <li
                        key={"agencias_" + e.id}
                        onClick={k => props.onClickAgencia(e.id)}
                      >
                        {e.description}
                      </li>
                    ))}
                  </ul>
                </th>
                <th>Marca</th>
                <th className="th-combo">
                  Tipo{" "}
                  <span className="th-triangule">
                    {" "}
                    <TiFilter />
                  </span>
                  <ul className="th-lista">
                    <li onClick={e => props.onClickTipo("")}>Todos</li>
                    {tipos.map(e => (
                      <li
                        key={"tipos_" + e.id}
                        onClick={k => props.onClickTipo(e.id)}
                      >
                        {e.name}
                      </li>
                    ))}
                  </ul>
                </th>
                <th className="th-combo">
                  Perido{" "}
                  <span className="th-triangule">
                    {" "}
                    <TiFilter />
                  </span>
                  <ul className="th-lista">
                    <li onClick={e => props.onClickPeriodo("")}>Todos</li>
                    {periodos.map(e => (
                      <li
                        key={"periodos_" + e.id}
                        onClick={k => props.onClickPeriodo(e.id)}
                      >
                        {e.slug}
                      </li>
                    ))}
                  </ul>
                </th>
                <th className="th-combo">
                  Estado{" "}
                  <span className="th-triangule">
                    {" "}
                    <TiFilter />
                  </span>
                  <ul className="th-lista">
                    <li onClick={e => props.onClickState("")}>Todos</li>
                    <li onClick={e => props.onClickState("aperturado")}>
                      En Revisión
                    </li>
                    <li onClick={e => props.onClickState("consolidado")}>
                      Completado
                    </li>
                  </ul>
                </th>
                <th className="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {!props.fichas.data ? (
                <tr />
              ) : (
                props.fichas.data.map(e => (
                  <tr key={"consolidado_" + e.id}>
                    <td>{e.agencia_name}</td>
                    <td>{e.marca_name}</td>
                    <td>{e.tipo_name}</td>
                    <td>{e.periodo_slug}</td>
                    <td>
                      {e.periodo === "aperturado" ? (
                        <span className="pil pil-warning">En Revisión</span>
                      ) : (
                        <span className="pil pil-success">Finalizado</span>
                      )}
                    </td>
                    <td className="text-center">
                      <span
                        onClick={k => props.handleContinue(k, e)}
                        className="link-default link-edit tooltip"
                      >
                        <GoPencil />
                        <span className="tooltiptext">Seguir evaluando</span>
                      </span>
                      {e.periodo === "consolidado" ? (
                        <span
                          onClick={k => props.handleResume(k, e)}
                          className="link-default link-edit tooltip"
                        >
                          <GoEye />
                          <span className="tooltiptext">Mostrar Resultado</span>
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}

      {!props.fichas.total ? (
        ""
      ) : (
        <div className="move-right">
          <div />
          <ReactPaginate
            previousLabel={"Atrás"}
            nextLabel={"siguiente"}
            breakLabel={<a href="#paginado">...</a>}
            breakClassName={"break-me"}
            pageCount={props.fichas.last_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={props.fichas.current_page - 1}
            onPageChange={props.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </div>
  );
};
