import React from "react";
import { Switch, Route } from "react-router-dom";

import LoginPage from './login/login-page';

import ListTipoPage from "./evaluacion/list-tipo-page";
import SelectionPage from "./evaluacion/selection-page";
import PasosPage from "./evaluacion/pasos-page";

import NotFound from "./errors/404";

import ListMarcaPage from "./marca/list-marca-page";
import FormMarcaPage from "./marca/form-marca-page";

import ListPaisesPage from "./paises/list-paises-page";
import FormPaisPage from "./paises/form-pais-page";

import ListAgenciaPage from "./agencia/list-agencia-page";
import FormAgenciaPage from "./agencia/form-agencia-page";

import ListPeriodoPage from "./periodo/list-periodo-page";
import FormPeriodoPage from "./periodo/form-periodo-page";

import ListUserPage from "./users/list-user-page";
import FormUserPage from "./users/form-user-page";
import PrivateRoute from 'components/PrivateRoute';
import ResumePage from './evaluacion/resume-page';
import ListConsolidadoPage from "./consolidado/list-consolidado-page";
import ConsolidatePage from './consolidado/consolidado-page';

import ListCategoriaMarcaPage from "./categoria-marca/list-categoria-marca-page";
import FormCategoriaMarcaPage from "./categoria-marca/form-categoria-marca-page";

import ListComentarios from "./comentarios/list-comentarios-page";
import FormComentarioPage from "./comentarios/form-comentarios-page";


import ListFichaPage from "./ficha/list-ficha-page";
import Logs from "./logs/logs";

export default () => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <PrivateRoute exact path="/" component={ListTipoPage} />
      <PrivateRoute exact path="/historial" component={ListFichaPage} />
      <PrivateRoute exact path="/logs" component={Logs} />

      <PrivateRoute exact path="/agencias" component={ListAgenciaPage} />
      <PrivateRoute path="/agencias/create" component={FormAgenciaPage} />
      <PrivateRoute path="/agencias/editar/:_name" component={FormAgenciaPage} />

      <PrivateRoute exact path="/marcas/" component={ListMarcaPage} />
      <PrivateRoute path="/marcas/create" component={FormMarcaPage} />
      <PrivateRoute path="/marcas/editar/:_name" component={FormMarcaPage} />

      <PrivateRoute exact path="/paises/" component={ListPaisesPage} />
      <PrivateRoute path="/paises/create" component={FormPaisPage} />
      <PrivateRoute path="/paises/editar/:_id" component={FormPaisPage} />

      <PrivateRoute exact path="/periodos" component={ListPeriodoPage} />
      <PrivateRoute path="/periodos/create" component={FormPeriodoPage} />
      <PrivateRoute path="/periodos/editar/:_slug" component={FormPeriodoPage} />

      <PrivateRoute exact path="/users" component={ListUserPage} />
      <PrivateRoute path="/users/create" component={FormUserPage} />
      <PrivateRoute path="/users/editar/:_id" component={FormUserPage} />


      <PrivateRoute exact path="/evaluacion" component={SelectionPage} />
      <PrivateRoute path="/evaluacion/:agencia" component={PasosPage} />

      <PrivateRoute exact path="/categoria-marcas/" component={ListCategoriaMarcaPage} />
      <PrivateRoute path="/categoria-marcas/create" component={FormCategoriaMarcaPage} />
      <PrivateRoute path="/categoria-marcas/editar/:_name" component={FormCategoriaMarcaPage} />

      <PrivateRoute exact path="/resume" component={ResumePage} />
      <PrivateRoute exact path="/consolidado/:_id" component={ConsolidatePage} />
      <PrivateRoute exact path="/consolidado-anual/:_id" component={ConsolidatePage} />
      <PrivateRoute exact path="/consolidados" component={ListConsolidadoPage} />

      <PrivateRoute exact path="/comentarios/" component={ListComentarios} />
      <PrivateRoute path="/comentarios/create" component={FormComentarioPage} />



      <PrivateRoute component={NotFound} />
    </Switch>
  )
}
