import React, { Component } from "react";
import ListPaso from "./list-paso";
import { ratingByKpis } from "./helper";

export default class ListPasoContainer extends Component {
  state = {
    rating: "N.A",
    questions: this.props.data.questions,
    inputs: {}
  };

  componentDidMount(){

    let {respuestas} = this.props.data;
    if(respuestas){
      if(respuestas.length){
        let inputs = JSON.parse(respuestas[0].answers); 
        let questions = this.state.questions.map(e => {
          e.asnwser = inputs["fila_" + e.id];
          return e;
        });
        this.setState({
          rating: respuestas[0].rating,
          questions, 
          inputs
        })

      }
    }

  }

  onChangeList = (e, data) => {
    let objecto = {};
    objecto["fila_" + data.id] = e.currentTarget.value;
    
    let { resultado, new_result } = ratingByKpis(
      e.currentTarget.value,
      data,
      this.state.questions
    );
    this.setState({
      questions: new_result,
      rating: resultado,
      inputs :  Object.assign({}, this.state.inputs, objecto)
    });
  };

  handleAdminSelect = e => {
    this.setState({
      rating: e.currentTarget.value,
    });
  }


  render() {
    return (
      <ListPaso
        data={this.props.data}
        rating={this.state.rating}
        inputs={this.state.inputs}
        onChangeList={this.onChangeList}
        handleAdminSelect={this.handleAdminSelect}
      />
    );
  }
}
