import React from "react";

/**
 *  Ojo aca estoy guardando el id de la evaluación
 * 
 */

const RowSelect = props => {
  return (
    <tr  className="table-line table-top ">
      <td className="bottom-hidden top-hidden" />
      <td>{props.data.name}</td>
      {props.data.result.map((k, indice) => (
        <td key={"clave_" + indice + props.data.orden} className="text-center">
          <select
            name={"result_past[" + k.id + "]"}
            className="consolidate_select"
            disabled={props.disabled}
            defaultValue={k.resp}
          >
            <option value="" />
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="N.A">N.A</option>
          </select>
          <input
            type="hidden"
            value={k.id}
            name={"evaluacion_id[" + k.id + "]"}
          />
        </td>
      ))}
    </tr>
  );
};

export default RowSelect;
