import { LOADING_USERS, USERS_LIST_SUCCESS, FAIL } from "./constants";

export function fetchUsers(value = "", state = '', page = 1, tipo = '') {
  return {
    types: [LOADING_USERS, USERS_LIST_SUCCESS, FAIL],
    payload: {
      request: {
        url: "/users?q=" + value + "&state=" + state + "&page="+ page + '&tipo=' + tipo
      }
    }
  };
}

export function saveUser(user) {
  return {
    type: "SAVE_USER",
    payload: {
      request: {
        method: "POST",
        url: "/users",
        data: user
      }
    }
  };
}

export function createUser() {
  return {
    type: "CREATE_USER",
    payload: {
      request: {
        url: "/users/create"
      }
    }
  };
}

export function fetchUser(_name) {
  return {
    type: "FETCH_USER",
    payload: {
      request: {
        url: "/users/" + _name + "/edit"
      }
    }
  };
}

export function updateUser(user) {
  return {
    type: "UPDATE_USER",
    payload: {
      request: {
        method: "PUT",
        url: "/users/" + user.id,
        data: user
      }
    }
  };
}

export function deleteUser(_name) {
  return {
    type: "DELETE_USER",
    payload: {
      request: {
        method: "DELETE",
        url: "/users/" + _name
      }
    }
  };
}
