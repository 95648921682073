import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Content from "routes/_hoc/Content";

import { fetchPaises, deletePais } from "modules/paises/actions/paises-actions";

import ListPaises from "./components/list-paises";
import swal from "sweetalert";

class ListPaisesPage extends Component {
  componentDidMount() {
    this.props.fetchPaises();
  }

  onConfirmDelete = (e, data) => {
    let ambito = this;
    e.preventDefault();
    swal({
      title: "Estás Seguro?",
      text:
        "una vez eliminado, no podras recuperar el pais " +
        data.nombre +
        "!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        ambito.props.deletePais(data.id).then(e => {
          if (e.type === "DELETE_MARCA_SUCCESS") {
            swal("La marca ha sido eliminada con éxito", {
              icon: "success"
            });
          }

          if (e.type === "DELETE_MARCA_FAIL") {
            if (e.error.response.status === 500) {
              swal("Ocurrió un problema, puede que se este usando en una evaluación.", {
                icon: "error"
              });
            }
          }
        });
      }
    });
  };

  render() {
    return (
      <section>
        <div className="acciones_top">
          <Link to="/paises/create" className="btn btn-primary">
            Crear Nuevo Pais
          </Link>
        </div>
        <ListPaises
          loading={this.props.loading_paises}
          data={this.props.paises}
          onConfirmDelete={this.onConfirmDelete}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  paises: state.paisesStore.paises,
  loading: state.paisesStore.loading_paises
});

export default connect(mapStateToProps, { fetchPaises, deletePais })(
  Content(ListPaisesPage, {
    title: "Listado de Países",
    links: [{ title: "Países" }]
  })
);
