import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Content from "routes/_hoc/Content";

import { fetchComentarios } from "modules/comentarios/comentarios-actions";

import ListComentarios from "./components/list-comentarios";
import Search from "components/Search";

class ListComentariosPage extends Component {
    state = {
        page: 1,
        periodo: '',
        search: ''
    };
    componentDidMount() {
        this.props.fetchComentarios(this.state.page);
    }

    onClickTipe = periodo => {
        this.props
            .fetchComentarios(1, periodo, this.state.search)
            .then(e => {
                this.setState({
                    periodo: periodo,
                    page: 1,
                    search: this.state.search
                });
            });
    }


    handlePageClick = page => {
        this.props
            .fetchComentarios(page.selected + 1, this.state.periodo, this.state.search)
            .then(e => {
                this.setState({
                    page: page.selected + 1,
                    search: this.state.search,
                    periodo: this.state.periodo,
                });
            });
    };

    handleSearch = value => {
        this.props.fetchComentarios(1, this.state.periodo, value).then(e => {
            this.setState({
                periodo: this.state.periodo,
                page: 1,
                search: value
            });
        });
    };

    onClean = e => {
        this.setState({
            page: 1,
            periodo: "",
            search: ""
        });
        this.props.fetchComentarios();
    };



    render() {
        const role = JSON.parse(localStorage.getItem('user')).role;
        return (
            <section>
                <div className="acciones_top espaciados">
                    {
                        role === 'marketing' && <div className="acciones_top">
                            <Link to="/comentarios/create" className="btn btn-primary">
                                Crear Comentario
                            </Link>
                        </div>
                    }
                    <div>
                        <Search handleSearch={this.handleSearch} />
                        {

                            (this.state.periodo.length > 0 || this.state.search.length > 0) && <div>
                                <span className="search-clean" onClick={this.onClean}>
                                    limpiar búsqueda
                                </span>
                            </div>
                        }

                    </div>
                </div>
                <div>

                </div>

                <ListComentarios
                    loading={this.props.loading_comentarios}
                    data={this.props.comentarios.data}
                    periodos={this.props.comentarios.periodos}
                    onClickTipe={this.onClickTipe}
                    role={role}
                    onConfirmDelete={this.onConfirmDelete}
                    handlePageClick={this.handlePageClick}
                />
            </section>
        );
    }
}

const mapStateToProps = state => ({
    comentarios: state.comentariosStore.comentarios,
    loading_comentarios: state.comentariosStore.loading_comentarios
});

export default connect(mapStateToProps, { fetchComentarios })(
    Content(ListComentariosPage, {
        title: "Listado de Comentarios",
        links: [{ title: "Comentarios" }]
    })
);
