import React, { Component } from "react";
import EvalContent from "routes/_hoc/EvalContent";
import InputPasoContainer from "./_pasos/input-paso-container";
import SharePasoContainer from "./_pasos/share-paso-container";
import ListPasoContainer from "./_pasos/list-paso-container";
import MetaPasoContainer from "./_pasos/meta-paso-container";
import NormaPasoContainer from "./_pasos/norma-paso-container";
import Comentario from "./_pasos/comentario";
import Multistep from "./Multistep";

class ListSteps extends Component {
  handleNext = e => this.props.changePosition(this.props.current_step + 1);

  handleBack = e => this.props.changePosition(this.props.current_step - 1);

  handleClick = i => this.props.changePosition(i);


  componentWillUnmount(){
    window.onbeforeunload = null;
  }
  
  render() {
    //!desactivar
    /* window.onbeforeunload = (e) => {
      return 'Stop this event';
    };  */
    if(this.props.pasos[this.props.pasos.length - 1].name !== "comentario")
      this.props.pasos.push({ name: "comentario" });

    let comentario = this.props.evaluacion === null ? '' : this.props.evaluacion.comentario;

    const pasos = this.props.pasos.map((e, i) => {
      return (
        <section
          key={"paso_" + e.id}
          style={{
            display: i + 1 === this.props.current_step ? "block" : "none"
          }}
        >
          {e.type === "input" ? (
            <InputPasoContainer data={e} ventaObjectivo={0} />
          ) : e.type === "list" ? (
            <ListPasoContainer data={e} />
          ) : e.type === 'share' ? (
            <SharePasoContainer data={e} />
          ) : e.type === 'meta' ? (
            <MetaPasoContainer data={e} />
          ) : e.type === 'norma' ? (
            <NormaPasoContainer data={e} />
          ) : '' }

          { e.name === 'comentario' ? <Comentario describe={comentario} /> : '' }
        </section>
      );
    });

    return (
      <form id="formulario">
        <Multistep
          pasos={this.props.pasos}
          onHandleSteps={this.props.onHandleSteps}
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          handleClick={this.handleClick}
          currentStep={this.props.current_step}
          onHandleResume={this.props.onHandleResume}
          onHandleSaveOut={this.props.onHandleSaveOut}
        >
          {pasos}
        </Multistep>
      </form>
    );
  }
}

export default EvalContent(ListSteps);
