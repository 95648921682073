import {
  LOADING_PAISES,
  PAISES_LIST_SUCCESS,
  FETCH_PAIS,
  FETCH_PAIS_SUCCESS,
  SAVE_PAIS_SUCCESS,
  UPDATE_PAIS_SUCCESS
} from "../../paises/constants";

const initialState = {
  loading_paises: true,
  paises: [], 
  pais: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_PAISES:
      return Object.assign({}, state, {
        loading_paises: true
      });

    case PAISES_LIST_SUCCESS:
      return Object.assign({}, state, {
        paises: action.payload.data,
        loading_paises: false
      });
    case "CREATE_PAIS_SUCCESS":
      return Object.assign({}, state, {
        pais: action.payload.data.pais,
    });
    case FETCH_PAIS:
      return Object.assign({}, state, {
        pais: {}
      });
    case FETCH_PAIS_SUCCESS:
      return Object.assign({}, state, {
        pais: action.payload.data, 
      });
    case SAVE_PAIS_SUCCESS:
      return Object.assign({}, state, {
        pais: action.payload.data
      });
    case UPDATE_PAIS_SUCCESS:
      return Object.assign({}, state, {
        pais: action.payload.data
      });
    case "DELETE_PAIS_SUCCESS":
      return Object.assign({}, state, {
        paises: state.paises.filter(e => e.id !== action.payload.data.id)
      });
    default:
      return state;
  }
};
