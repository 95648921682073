import React from "react";

export default function ClearValues(props) {
  return (
    <div>
      {props.estado || props.agencia || props.tipo || props.periodo ? (
        <span className="message-span">
          <i>
            {props.estado ? (
              <span>
                {" "}
                {props.estado === "consolidado"
                  ? "Finalizado"
                  : "En revisión"}{" "}
              </span>
            ) : (
              ""
            )}

            {props.agencia ? (
              <span>
                {JSON.parse(localStorage.getItem("filtros_agencias")).filter(
                  e => e.id === props.agencia
                )[0].description + " "}
                /
              </span>
            ) : (
              ""
            )}
            {props.tipo ? (
              <span>
                {JSON.parse(localStorage.getItem("filtros_tipos")).filter(
                  e => e.id === props.tipo
                )[0].name + " "}
                /
              </span>
            ) : (
              ""
            )}

            {props.periodo ? (
              <span>
                {JSON.parse(localStorage.getItem("filtros_periodos")).filter(
                  e => e.id === props.periodo
                )[0].slug + " "}
                /
              </span>
            ) : (
              ""
            )}

            <span className="search-clean" onClick={props.onClean}>
              {" "}
              limpiar búsqueda{" "}
            </span>
          </i>
        </span>
      ) : (
        ""
      )}
    </div>
  );
}
