import React, { Component } from "react";
import { connect } from "react-redux";
import Content from "routes/_hoc/Content";

import ListFicha from "./components/list-ficha";
import ClearValues from "./components/clear-values";
import { fetchFichas } from "modules/ficha/ficha-actions";

class ListFichaPage extends Component {
  state = {
    page: 1,
    periodo: "",
    tipo: "",
    agencia: "",
    estado: ""
  };
  componentDidMount() {
    this.props.fetchFichas();
  }

  handleResume = (e, data) => {
    localStorage.setItem("evaluacion_id", data.id);
    localStorage.setItem("selected_agencia", data.agencia_id);
    localStorage.setItem("selected_marca", data.marca_id);
    localStorage.setItem("selected_periodo", data.periodo_id);
    localStorage.setItem("selected_pais", data.pais_id);
    localStorage.setItem("tipo_selected", data.tipo_name);
    this.props.history.push({
      pathname: "/resume"
    });
  };

  handleContinue = (e, data) => {
    e.preventDefault();
    console.log(data);
    localStorage.setItem("evaluacion_id", data.id);
    localStorage.setItem("selected_agencia", data.agencia_id);
    localStorage.setItem("selected_marca", data.marca_id);
    localStorage.setItem("selected_periodo", data.periodo_id);
    localStorage.setItem("selected_pais", data.pais_id);
    localStorage.setItem("tipo_selected", data.tipo_name);
    this.props.history.push({
      pathname: "/evaluacion/" + data.agencia_id
    });
  };

  handlePageClick = page => {
    this.props
      .fetchFichas(
        this.state.estado,
        this.state.agencia,
        this.state.tipo,
        this.state.periodo,
        page.selected + 1
      )
      .then(e => {
        this.setState({
          page: page.selected + 1
        });
      });
  };

  onClickState = state => {
    this.props
      .fetchFichas(
        state,
        this.state.agencia,
        this.state.tipo,
        this.state.periodo,
        1
      )
      .then(e => {
        this.setState({
          estado: state,
          page: 1
        });
      });
  };

  onClickAgencia = agencia => {
    this.props
      .fetchFichas(
        this.state.estado,
        agencia,
        this.state.tipo,
        this.state.periodo,
        1
      )
      .then(e => {
        this.setState({
          agencia: agencia,
          page: 1
        });
      });
  };
  onClickTipo = tipo => {
    this.props
      .fetchFichas(
        this.state.estado,
        this.state.agencia,
        tipo,
        this.state.periodo,
        1
      )
      .then(e => {
        this.setState({
          tipo: tipo,
          page: 1
        });
      });
  };

  onClickPeriodo = periodo => {
    this.props
      .fetchFichas(
        this.state.estado,
        this.state.agencia,
        this.state.tipo,
        periodo,
        1
      )
      .then(e => {
        this.setState({
          periodo: periodo,
          page: 1
        });
      });
  };

  onClean = e => {
    this.props.fetchFichas().then(e => {
      this.setState({
        page: 1,
        periodo: "",
        tipo: "",
        agencia: "",
        estado: ""
      });
    });
  };

  render() {
    return (
      <section>
        <ClearValues
          agencia={this.state.agencia}
          estado={this.state.estado}
          tipo={this.state.tipo}
          periodo={this.state.periodo}
          onClean={this.onClean}
        />
        <ListFicha
          loading={this.props.loading}
          fichas={this.props.fichas}
          handleResume={this.handleResume}
          handleContinue={this.handleContinue}
          handlePageClick={this.handlePageClick}
          onClickState={this.onClickState}
          onClickAgencia={this.onClickAgencia}
          onClickTipo={this.onClickTipo}
          onClickPeriodo={this.onClickPeriodo}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  fichas: state.fichaStore.fichas,
  loading: state.fichaStore.loading_fichas
});

export default connect(mapStateToProps, { fetchFichas })(
  Content(ListFichaPage, {
    title: "Listado de Evaluaciones",
    links: [{ title: "Evaluaciones" }]
  })
);
