import React from "react";
import Loading from "components/Loading";
import { Link } from "react-router-dom";
import GoTrashcan from "react-icons/lib/go/trashcan";
import GoPencil from "react-icons/lib/go/pencil";
import TiFilter from "react-icons/lib/ti/filter";
import ReactPaginate from "react-paginate";

export default props => {
  return (
    <div className="element-box">
      {props.loading ? (
        <Loading />
      ) : (
        <div className="table-responsive">
          <table className="table table-lightborder table-striped">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th className="th-combo">
                  Tipo{" "}
                  <span className="th-triangule">
                    {" "}
                    <TiFilter />
                  </span>
                  <ul className="th-lista">
                    <li  onClick={e => props.onClickTipe("")}>Todos</li>
                    <li  onClick={e => props.onClickTipe("admin")}>Administración</li>
                    <li  onClick={e => props.onClickTipe("marketing")}>Markting</li>
                  </ul>
                </th>
                <th className="th-combo">
                  Estado{" "}
                  <span className="th-triangule">
                    {" "}
                    <TiFilter />
                  </span>
                  <ul className="th-lista">
                    <li onClick={e => props.onClickState("")}>Todos</li>
                    <li onClick={e => props.onClickState("active")}>Activo</li>
                    <li onClick={e => props.onClickState("inactive")}>
                      Inactivo
                    </li>
                  </ul>
                </th>
                <th className="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {props.users.data.map(e => (
                <tr key={"marca_" + e.id}>
                  <td>{e.name}</td>
                  <td>{e.email}</td>
                  <td>{e.role === "admin" ? "Administrador" : "Marketing"}</td>
                  <td>
                    {e.state ? (
                      <span className="pil pil-success">activo</span>
                    ) : (
                      <span className="pil pil-danger">Inactivo</span>
                    )}
                  </td>
                  <td className="text-center">
                    <Link
                      to={"/users/editar/" + e.id}
                      className="link-default link-edit tooltip"
                    >
                      <GoPencil />
                      <span className="tooltiptext">Editar</span>
                    </Link>
                    <span
                      className="link-default link-delete tooltip"
                      onClick={k => props.onConfirmDelete(k, e)}
                    >
                      <GoTrashcan />
                      <span className="tooltiptext">Eliminar</span>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!props.users.total ? (
        ""
      ) : (
        <div className="move-right">
          <div />
          <ReactPaginate
            previousLabel={"Atrás"}
            nextLabel={"siguiente"}
            breakLabel={<a href="#paginate">...</a>}
            breakClassName={"break-me"}
            pageCount={props.users.last_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={props.users.current_page - 1}
            onPageChange={props.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </div>
  );
};
