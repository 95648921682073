export function getEval(id){
    return {
        types: ['LOADING_EVAL', 'EVAL_SUCCESS', 'FAIL'],
        payload: {
          request: {
            method: 'GET',
            url: "/get-eval?id=" + id
          }
        }
    };
}

export function saveConsolidate(data){
  return {
    types: ['LOADING_CONSOLIDATE', 'CONSOLIDATE_SUCCESS', 'FAIL'],
    payload: {
      request: {
        method: 'POST',
        url: "/consolidado-save",
        data : {
          'periodo_id' : localStorage.getItem('selected_periodo'),
          'marca_id' : localStorage.getItem('selected_marca'),
          'evaluacion_id' : localStorage.getItem('evaluacion_id'),
          'agencia_id' : localStorage.getItem('selected_agencia'),
          'tipo_selected' : localStorage.getItem('tipo_selected')
        }
      }
    }
  }
}