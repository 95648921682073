import React, { Component } from "react";

export default class NormaPaso extends Component {
  render() {
    let { inputs } = this.props;
    const disabled =
      JSON.parse(localStorage.getItem("user")).role === "marketing"
        ? ""
        : "disbaled";

    return (
      <section className="pasos">
        <h3>{this.props.data.objective}</h3>
        <span className="text-mini">{this.props.data.measurement_unit}</span>
        {this.props.data.alert_message && <p className="label label-danger">{this.props.data.alert_message}</p>}
        <ul className="lista-ali">
          {this.props.data.questions.map((e, i) => (
            <li key={"cuantitativo_" + i}>
              <input
                type="hidden"
                name={"rating_" + e.id + "[" + this.props.data.id + "]"}
                value={inputs["rating_" + e.id] || ""}
              />
              <input
                type="hidden"
                name={"disabled_" + e.id + "[" + this.props.data.id + "]"}
                value={inputs["disabled_" + e.id] || ""}
              />
              <span>
                <span className="norma-space">{e.name}</span>
                Norma de la categoría :
                <input
                  className="input-norma"
                  onChange={k => this.props.handleNormaInput(k, e)}
                  name={"input_norma_" + e.id + "[" + this.props.data.id + "]"}
                  value={inputs["input_norma_" + e.id] || ""}
                  type="text"
                  disabled={
                    disabled
                      ? disabled
                      : inputs["disabled_" + e.id]
                        ? "disabled"
                        : ""
                  }
                />
                Resultado:
                <input
                  className="input-norma"
                  onChange={k => this.props.handleResultadoInput(k, e)}
                  name={"input_result_" + e.id + "[" + this.props.data.id + "]"}
                  value={inputs["input_result_" + e.id] || ""}
                  type="text"
                  disabled={
                    disabled
                      ? disabled
                      : inputs["disabled_" + e.id]
                        ? "disabled"
                        : ""
                  }
                />
                <input
                  type="checkbox"
                  onChange={k => this.props.handleChechbox(k, e)}
                  name={
                    "disabled_check_" + e.id + "[" + this.props.data.id + "]"
                  }
                  checked={inputs["disabled_" + e.id] || ""}
                  id={"disabled_check_" + e.id}
                  disabled={disabled}
                />
                <label htmlFor={"disabled_check_" + e.id}>
                  Sin información
                </label>
              </span>
              <span className="text-center rating-color-na norma-row">
                {inputs["rating_" + e.id]}
              </span>
            </li>
          ))}
        </ul>
        <div className="pasos-result">
          <h3>RATING: </h3>
          <h1
            className={
              "rating-" +
              (this.props.rating === "N.A"
                ? "na"
                : this.props.rating.toLowerCase())
            }
          >
            {disabled ? (
              <select
                className="select-admin"
                onChange={k => this.props.handleAdminSelect(k)}
                value={this.props.rating}
              >
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="N.A">N.A</option>
              </select>
            ) : (
              this.props.rating
            )}
          </h1>
        </div>
        <input
          type="hidden"
          name={"rating[" + this.props.data.id + "]"}
          value={this.props.rating}
        />
        <input
          type="hidden"
          name={"paso_id[" + this.props.data.id + "]"}
          value={this.props.data.id}
        />
      </section>
    );
  }
}
