import React, { Component } from "react";
import Resume from "./components/resume";
import { connect } from "react-redux";
import swal from "sweetalert";
import Loading from "components/Loading";

import {
  getEval,
  saveConsolidate
} from "modules/evaluacion/actions/result-actions";

class ResumePage extends Component {
  componentDidMount() {
    this.props.getEval(localStorage.getItem("evaluacion_id"));
  }

  onHandleConsolidated = e => {
    let ambito = this;
    swal({
      title: "Estás Seguro?",
      icon: "warning",
      buttons: true
    }).then(bandera => {
      if (bandera) {
        ambito.props.saveConsolidate().then(e => {
          swal("Evaluación guardada con éxito", {
            icon: "success"
          }).then(bandera => {
            setTimeout(() => {
              ambito.props.history.push({
                pathname: "/historial"
              });
            }, 1000);
          });
        });
      }
    });
  };

  handleContinue = (e, data) => {
      e.preventDefault();

      localStorage.setItem("evaluacion_id", data.id);
      localStorage.setItem("selected_agencia", data.agencia_id);
      localStorage.setItem("selected_marca", data.marca_id);
      localStorage.setItem("selected_periodo", data.periodo_id);
      localStorage.setItem("tipo_selected", data.tipo_name);
      this.props.history.push({
        pathname: "/evaluacion/" + data.agencia_id
      });
  };

  render() {
    const { pasos, respuestas, evaluacion,evaluador, loading_evaluacion, logs } = this.props;
    return loading_evaluacion ? (
      <Loading />
    ) : (
      <Resume
        links={[
          {
            title: "Resumen de evaluar " + localStorage.getItem("tipo_selected")
          }
        ]}
        title={"Resumen de evaluar " + localStorage.getItem("tipo_selected")}
        pasos={pasos}
        respuestas={respuestas}
        evaluacion={evaluacion}
        evaluador={evaluador}
        logs={logs}
        onHandleConsolidated={this.onHandleConsolidated}
        handleContinue={this.handleContinue}
      />
    );
  }
}

const mapStateToProps = state => ({
  evaluacion: state.pasoStore.evaluacion,
  logs: state.pasoStore.logs,
  loading_evaluacion: state.pasoStore.loading_evaluacion,
  pasos: state.pasoStore.pasos,
  evaluador: state.pasoStore.evaluador,
  evaluacion_id: state.pasoStore.evaluacion_id,
  respuestas: state.pasoStore.answers
});

export default connect(mapStateToProps, { getEval, saveConsolidate })(
  ResumePage
);
