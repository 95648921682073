import { LOADING_AGENCIA, 
    AGENCIAS_SUCCESS, 
    LOADING_MARCA,
    MARCAS_SUCCESS,
    LOADING_PERIODO,
    PERDIODOS_SUCCESS,
    SELECTED_AGENCIA,
    SELECTED_MARCA,
    SELECTED_PERIODO,
    PAISES_SUCCESS,
    LOADING_PAISES,
    SELECTED_PAIS,
     } from '../constants'



/* const initialState =  {
    agencias : localStorage.getItem('agencias') ? JSON.parse(localStorage.getItem('agencias')):  [] ,
    marcas : localStorage.getItem('agencias') ?  JSON.parse(localStorage.getItem('agencias')) :  [],
    periodos : localStorage.getItem('periodos') ?   JSON.parse(localStorage.getItem('agencias')) : [],
    loading_agencias : false,
    loading_marcas : false,
    loading_periodos : false,
    selected_agencia : localStorage.getItem('selected_agencia') || 'Seleccione',
    selected_marca : localStorage.getItem('selected_marca') || 'Seleccione',
    selected_periodo : localStorage.getItem('selected_periodo') || 'Seleccione'
}
 */
const initialState =  {
    agencias : [] ,
    marcas : [],
    periodos : [],
    paises :[],
    loading_agencias : false,
    loading_marcas : false,
    loading_periodos : false,
    loading_paises : false,
    selected_agencia : 'Seleccione',
    selected_marca :  'Seleccione',
    selected_periodo :  'Seleccione',
    selected_pais :  'Seleccione'
}

export default function evaluacion(state = initialState, action) {
    switch (action.type) {
        case LOADING_AGENCIA : 
            return Object.assign({}, state,{
                loading_agencias: true
            })
        case AGENCIAS_SUCCESS :
            return Object.assign({}, state, {
                agencias : action.payload.data,
                selected_agencia : 'Seleccione',
                selected_periodo : 'Seleccione',
                selected_marca : 'Seleccione', 
                loading_agencias: false,
                error_evaluacion: false,
            })
        case LOADING_MARCA : 
            return Object.assign({}, state,{
                loading_marcas: true
            })
        case MARCAS_SUCCESS:
            return Object.assign({}, state,{
                marcas : action.payload.data,
                loading_marcas: false,
                error_evaluacion: false,
                
            })
        case LOADING_PERIODO: 
            return Object.assign({}, state,{
                loading_periodos : true
            })
        case PERDIODOS_SUCCESS:
            return Object.assign({}, state, {
                periodos : action.payload.data,
                loading_periodos : false,
                error_evaluacion: false
            })
        case SELECTED_AGENCIA:
            return Object.assign({}, state, {
                selected_agencia: action.selected
            })

        case SELECTED_MARCA:
            return Object.assign({}, state, {
                selected_marca: action.selected
            })
        case SELECTED_PERIODO:
            return Object.assign({}, state, {
                selected_periodo: action.selected
            })
        case "CLEAN_FORM":
            return Object.assign({}, state, {
                agencias : [],
                marcas : [],
                periodos : [],
                selected_agencia : 'Seleccione',
                selected_periodo : 'Seleccione',
                selected_marca : 'Seleccione',
                selected_pais : 'Seleccione',
                loading_agencias : false,
                loading_marcas : false,
                loading_periodos : false,
                loading_pais : false
            })
        case SELECTED_PAIS:
            return Object.assign({}, state, {
                selected_pais: action.selected,
                selected_agencia : 'Seleccione',
                selected_periodo : 'Seleccione',
                selected_marca : 'Seleccione',  
            })
        case LOADING_PAISES : 
            return Object.assign({}, state,{
                loading_paises: true
            })
        case PAISES_SUCCESS:
            return Object.assign({}, state,{
                paises : action.payload.data,
                loading_paises: false,
                error_evaluacion: false,
                 
                
            })
        default:
            return state
    }
}